import * as consts from '../../constants.js';

export default {
  userStatusLoad: consts.REQUEST_WITHOUT_STARTING,
  dataManagmentStatus: {
    work_areas: consts.REQUEST_WITHOUT_STARTING,
    contact_means: consts.REQUEST_WITHOUT_STARTING,
    services: consts.REQUEST_WITHOUT_STARTING,
    work_equipment: consts.REQUEST_WITHOUT_STARTING,
    associations: consts.REQUEST_WITHOUT_STARTING,
    languages: consts.REQUEST_WITHOUT_STARTING,
    race: consts.REQUEST_WITHOUT_STARTING,
    office_name: consts.REQUEST_WITHOUT_STARTING,
    more_information: consts.REQUEST_WITHOUT_STARTING,
    age_range: consts.REQUEST_WITHOUT_STARTING,
  },
  user: null,
  workAreas: [],
  changePasswordStatus: consts.REQUEST_WITHOUT_STARTING,
  hideProfileTimeStatus: consts.REQUEST_WITHOUT_STARTING,
  showProfileStatus: consts.REQUEST_WITHOUT_STARTING,
  errorsChangePassword: null,
};
