/*
|-------------------------------------------------------------------------------
| API api/contact_us.js
|-------------------------------------------------------------------------------
*/

export default {

  /**
   * Envía formulario de contacto.
   *
   * @return     {Promise}
   */
  sendMessage(contact) {
    return axios.post('/api/contact-us/send-message', contact);
  },

};
