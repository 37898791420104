/*
|-------------------------------------------------------------------------------
| modules/associations/actions.js
|-------------------------------------------------------------------------------
| The Vuex data store for associations that may belong to the user
*/

import * as consts from '../../constants.js';
import AssociationsAPI from '../../api/associations.js';

export default {
  /**
     * Obtiene las asociaciones con las que un usuario puede trabajar
     *
     * @param      {int}    id
     */
  async getAssociations({ commit }) {
    commit('setAssociationsStatusLoad', consts.REQUEST_HAS_STARTED);

    let response;

    try {
      response = await AssociationsAPI.getAssociations();
    } catch (error) {
      commit('setAssociationsStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      return;
    }

    commit('setAssociations', response.data);
    commit('setAssociationsStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
  },

};
