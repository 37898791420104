/*
|-------------------------------------------------------------------------------
| modules/user/mutations.js
|-------------------------------------------------------------------------------
| The mutations Vuex for users module
*/

export default {
  setUserStatusLoad(state, status) {
    state.userStatusLoad = status;
  },
  setUser(state, user) {
    state.user = user;
  },

  setDataManagmentStatus(state, data) {
    state.dataManagmentStatus[data.key] = data.status;
  },

  /**
   * Establece las áreas de trabajo.
   *
   * @param      {object}  state
    * @param      {object}  newWorkArea
    */
  setWorkAreas(state, workAreas) {
    state.user.work_areas = workAreas;
  },

  /**
   * Establece los servicios que presta el usuario
   *
   * @param      {object}  state
   * @param      {object}  services
   */
  setServices(state, services) {
    state.user.services = services;
  },

  /**
   * Establece los idiomas que presta el usuario
   *
   * @param      {object}  state
   * @param      {object}  languages
   */
  setLanguages(state, languages) {
    state.user.languages = languages;
  },

  /**
   * Establece los equipos de trabajo de los que dispone el usuario
   *
   * @param      {object}  state
   * @param      {object}  workEquipment
   */
  setWorkEquipment(state, workEquipment) {
    state.user.work_equipment = workEquipment;
  },

  /**
   * Establece las asociacionse a las que pertenece el usuario
   *
   * @param      {object}  state
   * @param      {object}  associations
   */
  setAssociations(state, associations) {
    state.user.associations = associations;
  },

  /**
   * Establece los medios de contactos
   *
   * @param      {<type>}  state         The state
   * @param      {<type>}  contactMeans  The contact means
   */
  setContactMeans(state, contactMeans) {
    state.user.contact_means = contactMeans;
  },

  setRace(state, race) {
    state.user.race = race;
  },

  setMoreInformation(state, moreInformation) {
    state.user.more_information = moreInformation;
  },

  setOfficeName(state, officeName) {
    state.user.office_name = officeName;
  },

  setAgeRange(state, ageRange) {
    state.user.age_range = ageRange;
  },

  setChangePasswordStatus(state, status) {
    state.changePasswordStatus = status;
  },

  setHideProfileTimeStatus(state, status) {
    state.hideProfileTimeStatus = status;
  },

  setShowProfileStatus(state, status) {
    state.showProfileStatus = status;
  },

  setErrorsChangePassword(state, errors) {
    state.errorsChangePassword = errors;
  },

};
