/*
|-------------------------------------------------------------------------------
| API api/search.js
|-------------------------------------------------------------------------------
| The api for api search
*/

export default {

  /**
	 * Get list collaboration requests.
	 *
	 * @param      {object}  filter  The filters
	 * @return     {Promise}
	 */
  search(filter) {
    return axios.get('/api/collaborations/search-request/', {
      params: filter,
    });
  },

};
