/*
|-------------------------------------------------------------------------------
| API api/locations.js
|-------------------------------------------------------------------------------
| The api for get locations
*/

export default {

  loadLocations(query) {
    return axios({
      url: '/api/locations',
      method: 'get',
      params: query,
    });
  },

  /* logout: function (credentials) {
		return axios.post('/login', credentials);
	}, */

};
