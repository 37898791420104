/*
|-------------------------------------------------------------------------------
| modules/collaborations/request/actions.js
|-------------------------------------------------------------------------------
| The actions Vuex for request collaborations module
*/

import * as consts from '@/constants.js';
import CollaborationAPI from '@/api/collaborations/collaboration.js';
import CollaborationRequestSearchAPI from '@/api/search/collaboration_request.js';

export default {

  admin({ commit }, collaboration) {
    commit('setRequestCollaborationStatusLoad', consts.REQUEST_HAS_STARTED);

    CollaborationAPI.admin(collaboration)
      .then((response) => {
        if (response.status >= 200 && response.status <= 299 && _.has(response, 'data')) {
          commit('setRequestCollaboration', response.data);
          commit('setRequestCollaborationStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
          return;
        }

        if (response.status === 422 && _.has(response, 'data.payload.msg')) {
          commit('setErrorsFormrequestCollaboration', response.data.payload.msg);
        }

        commit('setRequestCollaborationStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      })
      .catch((/* response */) => {
        commit('setRequestCollaborationStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      });
  },

  stop({ commit }) {
    commit('setRequestCollaborationStatusLoad', consts.REQUEST_WITHOUT_STARTING);
  },

  search({ commit }, filter) {
    commit('setCollaborationRequestsStatusLoad', consts.REQUEST_HAS_STARTED);
    CollaborationRequestSearchAPI.search(filter)
      .then((response) => {
        if (_.has(response, 'data') && response.status === 200) {
          commit('setCollaborationRequests', response.data);
          commit('setCollaborationRequestsStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
          return;
        }

        commit('setCollaborationRequestsStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      })
      .catch((response) => {
        commit('setCollaborationRequestsStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      });
  },

};
