/*
|-------------------------------------------------------------------------------
| modules/services/mutations.js
|-------------------------------------------------------------------------------
| The mutations Vuex for services module
*/

export default {

  /**
     * Indica el estado en el que se encuentra la obtención de servicios disponibles al servidor
     *
     * @param {object} state
     * @param {numeric} status
     */
  setServicesStatusLoad(state, status) {
    state.dataServicesStatus = status;
  },

  /**
     * Establece los servicios que puede prestar un usuario
     *
     * @param {object} state
     * @param {array} services
     */
  setServices(state, services) {
    state.services = services;
  },

};
