/*
|-------------------------------------------------------------------------------
| API api/user.js
|-------------------------------------------------------------------------------
| The api for api user
*/

export default {

  /**
	 * Obtiene los servicios que puede prestar un usuario.
	 *
	 * @param      {string}  idUser  The identifier user
	 * @return     {<type>}  The user.
	 */
  getServices(idUser) {
    return axios.get('/api/services');
  },

};
