/*
|-------------------------------------------------------------------------------
| modules/users/filters/mutations.js
|-------------------------------------------------------------------------------
| The mutations Vuex for filters module
*/

export default {

  setLocation(state, location) {
    state.filters.location = location;
  },

  setGender(state, gender) {
    state.filters.gender = gender;
  },

  setService(state, service) {
    state.filters.service = service;
  },

  setWorkEquipment(state, workEquipment) {
    state.filters.work_equipment = workEquipment;
  },

  setAssociation(state, association) {
    state.filters.association = association;
  },

  setAgeRange(state, ageRange) {
    state.filters.age_range = ageRange;
  },

  setLanguages(state, languages) {
    state.filters.languages = languages;
  },
};
