/*
|-------------------------------------------------------------------------------
| modules/races/actions.js
|-------------------------------------------------------------------------------
| The Vuex data store for races that may belong to the user
*/

import * as consts from '@/constants.js';
import RacesAPI from '@/api/races.js';

export default {
  /**
     * Obtiene los idiomas
     *
     * @param      {int}    id
     */
  async getRaces({ commit }) {
    commit('setRacesStatusLoad', consts.REQUEST_HAS_STARTED);

    let response;

    try {
      response = await RacesAPI.getRaces();
    } catch (error) {
      commit('setRacesStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      return;
    }

    commit('setRaces', response.data);
    commit('setRacesStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
  },

};
