import * as consts from '../../constants.js';

export default {

  methods: {
    changedStatusEditData(status) {
      let msg; let
        type;
      if (status == consts.REQUEST_COMPLETE_SUCCESSFULLY) {
        msg = 'Datos actualizados correctamente';
        type = 'success';
      } else if (status == consts.REQUEST_COMPLETE_UNSUCCESSFULLY) {
        msg = 'Ocurrió un error al actualizar los datos';
        type = 'warn';
      }

      if (typeof msg === 'undefined') {
        return;
      }

      this.$notify({
        group: 'save-data',
        clean: true,
      });

      this.$notify({
        group: 'save-data',
        text: msg,
        type,
      });
    },
  },

};
