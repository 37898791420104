/*
|-------------------------------------------------------------------------------
| modules/collaborations/collaboration/actions.js
|-------------------------------------------------------------------------------
| The actions Vuex for request collaborations module
*/

import * as consts from '@/constants.js';
import CollaborationAPI from '@/api/collaborations/collaboration.js';

export default {

  get({ commit }, collaborationId) {
    commit('setCollaborationStatusLoad', consts.REQUEST_HAS_STARTED);

    CollaborationAPI.get(collaborationId)
      .then((response) => {
        if (response.status === 200) {
          console.log(
            'Respuesta de coger colaboración',
            response,
          );

          commit('setCollaborationStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
          commit('setCollaboration', response.data);
          return;
        }

        commit('setCollaborationStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      })
      .catch((/* response */) => {
        commit('setCollaborationStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      });
  },

  disable({ commit }, { id, disable }) {
    commit('setDisableCollaborationRequestStatusLoad', consts.REQUEST_HAS_STARTED);
    CollaborationAPI.disable(id, disable)
      .then((response) => {
        if (response.status === 200) {
          commit('setDisableCollaborationRequestStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
          commit('setDisableCollaborationRequest', disable);
          return;
        }

        commit('setDisableCollaborationRequestStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      })
      .catch((/* response */) => {
        commit('setDisableCollaborationRequestStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      });
  },

  delete({ commit }, { id }) {
    commit('setDeleteCollaborationRequestStatusLoad', consts.REQUEST_HAS_STARTED);
    CollaborationAPI.delete(id)
      .then((response) => {
        if (response.status === 200) {
          commit('setDeleteCollaborationRequestStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
          return;
        }

        commit('setDeleteCollaborationRequestStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      })
      .catch((/* response */) => {
        commit('setDeleteCollaborationRequestStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      });
  },

};
