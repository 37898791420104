import Avatar from 'vue-avatar';
import * as consts from '@/constants.js';
import { mapGetters } from 'vuex';
import {
  mdiClose,
  mdiFilterOutline,
  mdiFilterRemoveOutline,
  mdiAccountMultipleRemoveOutline,
  mdiAccountSearchOutline,
} from '@mdi/js';
import WorkAreaSelectComponent from '../../components/WorkAreaSelectComponent/WorkAreaSelectComponent';

export default {

  name: 'page-search-users',

  data: () => ({
    location: null,
    showFiltersMvl: true,
    icons: {
      mdiClose,
      mdiFilterOutline,
      mdiFilterRemoveOutline,
      mdiAccountMultipleRemoveOutline,
      mdiAccountSearchOutline,
    },
    page: 1,
    searchIdiom: null,
    filtersLanguages: [],
  }),

  created() {
    this.page = 1;

    if (!Array.isArray(this.services) || !this.services.length) {
      this.$store.dispatch('services/getServices');
    }

    if (!Array.isArray(this.workEquipment) || !this.workEquipment.length) {
      this.$store.dispatch('workEquipment/getWorkEquipment');
    }

    if (!Array.isArray(this.associations) || !this.associations.length) {
      this.$store.dispatch('associations/getAssociations');
    }

    if (!Array.isArray(this.ageRanges) || !this.ageRanges.length) {
      this.$store.dispatch('ages/getAgeRanges');
    }

    if (!Array.isArray(this.languages) || !this.languages.length) {
      this.$store.dispatch('languages/getLanguages');
    }
  },

  mounted() {
    // Tenemos guardado
    if (this.locationFilter !== null) {
      this.$refs.workArea.setLocation(
        this.locationFilter,
      );
    }
    this.filtersLanguages = this.languagesFilter.map(
      (countryCode) => this.languages.find((language) => language.country_code === countryCode),
    );
  },

  computed: {

    filter() {
      return this.$store.getters['filterUsers/getFilters'];
    },

    locationFilter() {
      return this.filter.location;
    },

    genderFilter: {
      get() {
        return this.filter.gender;
      },
      set(val) {
        this.$store.commit('filterUsers/setGender', val);
      },
    },

    serviceFilter: {
      get() {
        return this.filter.service;
      },
      set(val) {
        this.$store.commit('filterUsers/setService', val);
      },
    },

    workEquipmentFilter: {
      get() {
        return this.filter.work_equipment;
      },
      set(val) {
        this.$store.commit('filterUsers/setWorkEquipment', val);
      },
    },

    associationFilter: {
      get() {
        return this.filter.association;
      },
      set(val) {
        this.$store.commit('filterUsers/setAssociation', val);
      },
    },

    ageRangeFilter: {
      get() {
        return this.filter.age_range;
      },
      set(val) {
        this.$store.commit('filterUsers/setAgeRange', val);
      },
    },

    languagesFilter: {
      get() {
        return this.filter.languages;
      },
      set(val) {
        this.$store.commit('filterUsers/setLanguages', val);
      },
    },

    // Indica si está haciendo una búsqueda
    isLoading() {
      return this.searchUsersStatusLoad === consts.REQUEST_HAS_STARTED;
    },

    ...mapGetters({
      users: 'users/getUsers',
      services: 'services/getServices',
      workEquipment: 'workEquipment/getWorkEquipment',
      associations: 'associations/getAssociations',
      ageRangesApi: 'ages/getAgeRanges',
      languages: 'languages/getLanguages',

      metaCR: 'users/getMetaUsers',
      searchUsersStatusLoad: 'users/getSearchUsersStatusLoad',
      firstSearch: 'users/getFirstSearch',
    }),

    ageRanges() {
      return this.ageRangesApi.slice(1);
    },

    showFilters() {
      return this.$vuetify.breakpoint.mdAndUp || this.showFiltersMvl;
    },

    notFoundUsers() {
      return Array.isArray(this.users) && this.users.length === 0;
    },

  },

  watch: {
    page() {
      this.applyFilter();
    },
    searchIdiom(val) {
      val && val.length > 2 && this.querySelectionsLanguges(val);
    },
    searchUsersStatusLoad(val) {
      if (val === consts.REQUEST_HAS_STARTED) {
        console.log('Vamos a setear');
        this.$store.commit('users/setFirstSearch');
        this.showFiltersMvl = false;
      }
    },
  },

  methods: {
    // Se ha seleccionado una localización.
    selectedLocation(selectedLocation) {
      const location = selectedLocation.currentAdminAreaLevel
        ? {
          admin_area_level: selectedLocation.newLocation.adminAreaLevel,
          admin_area_id: selectedLocation.newLocation.adminAreaId,
          country: selectedLocation.newLocation.country,
        }
        : null;
      this.$store.commit('filterUsers/setLocation', location);
    },

    fullName(u) {
      const shortName = `${u.name} ${u.surname}`;
      return u.second_surname ? `${shortName} ${u.second_surname}` : shortName;
    },

    applyFilter() {

      let filter = _.cloneDeep(this.filter);
      if (this.page > 1) {
        filter = { ...filter, page: this.page };
      }

      this.$store.dispatch(
        'users/filter',
        filter,
      );
    },

    querySelectionsLanguges(v) {
      this.filtersLanguages = this.languages.filter(l => (l.idiom || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1);
    },

  },

  components: {
    Avatar,
    WorkAreaSelectComponent,
  },


};
