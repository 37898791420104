/*
|-------------------------------------------------------------------------------
| modules/associations/mutations.js
|-------------------------------------------------------------------------------
| The Vuex data store for associations that may belong to the user
*/

export default {

  /**
     * Indica el estado en el que se encuentra la obtención de asociaciones disponibles en el servidor
     *
     * @param {object} state
     * @param {numeric} status
     */
  setAssociationsStatusLoad(state, status) {
    state.dataAssociationsStatus = status;
  },

  /**
     * Establece las asociaciones
     *
     * @param {object} state
     * @param {array} associations
     */
  setAssociations(state, associations) {
    state.associations = associations;
  },

};
