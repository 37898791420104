/*
|-------------------------------------------------------------------------------
| API api/work_equipment.js
|-------------------------------------------------------------------------------
| The api for managment work equipments.
*/

export default {

  /**
	 * Obtiene las herramientas de trabajo que puede tener un usuario.
	 *
	 * @param      {string}  idUser  The identifier user
	 * @return     {Promise}
	 */
  getWorkEquipment(idUser) {
    return axios.get('/api/work-equipments');
  },

};
