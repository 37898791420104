/*
|-------------------------------------------------------------------------------
| API api/races.js
|-------------------------------------------------------------------------------
| The api for managment races.
*/

export default {

  /**
	 * Get the available races.
	 *
	 * @return     {Promise}
	 */
  getRaces() {
    return axios.get('/api/races');
  },

};
