/*
|-------------------------------------------------------------------------------
| modules/collaborations/collaboration/mutations.js
|-------------------------------------------------------------------------------
| The mutations Vuex for request collaborations module
*/

export default {

  setCollaboration(state, collaboration) {
    state.collaboration = collaboration;
  },

  setDisableCollaborationRequest(state, disable) {
    state.collaboration.disabled = disable;
  },

  setCollaborationStatusLoad(state, status) {
    state.collaborationStatusLoad = status;
  },

  setDisableCollaborationRequestStatusLoad(state, status) {
    state.disableCollaborationRequestStatusLoad = status;
  },

  setDeleteCollaborationRequestStatusLoad(state, status) {
    state.deleteCollaborationRequestStatusLoad = status;
  },

};
