/*
|-------------------------------------------------------------------------------
| API modules/user/index.js
|-------------------------------------------------------------------------------
| The Vuex data store for users
*/

import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './state';

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
