
import * as consts from '@/constants.js';
import { mapGetters } from 'vuex';
import {
  mdiAccountLockOutline,
  mdiEye,
  mdiEyeOff,
  mdiClose,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiAlertOctagonOutline,
} from '@mdi/js';
import moment from 'moment';

export default {
  name: 'page-account',
  data: () => ({
    icons: {
      mdiAccountLockOutline,
      mdiEye,
      mdiEyeOff,
      mdiClose,
      mdiEyeOffOutline,
      mdiEyeOutline,
      mdiAlertOctagonOutline,
    },

    panels: [],

    // Profile
    hidden_profile_time: null,
    form_change_password: {
      current_password: '',
      new_password: '',
      new_password_confirmation: '',
    },
    show_current_password: false,
    shownew_password: false,

    snackbar: {
      status: false,
      text: '',
    },

  }),

  created() {
    moment.locale('es');
    this.checkHiddenUp();
  },

  computed: {

    ...mapGetters({
      profile: 'auth/getProfile',
      changePasswordStatus: 'user/getChangePasswordStatus',
      hideProfileTimeStatus: 'user/getHideProfileTimeStatus',
      showProfileStatus: 'user/getShowProfileStatus',
      errorsFormChangePassword: 'user/getErrorsChangePassword',
    }),

    changingPassword() {
      return this.changePasswordStatus === consts.REQUEST_HAS_STARTED;
    },

    hidingProfile() {
      return this.hideProfileTimeStatus === consts.REQUEST_HAS_STARTED;
    },

    showingProfile() {
      return this.showProfileStatus === consts.REQUEST_HAS_STARTED;
    },

    hiddenUp() {
      if (this.hidden_profile_time) {
        const date = moment().add(this.hidden_profile_time, 'minutes').format('llll');
        return `<strong v-html="rawHtml">Oculto hasta:</strong> ${date}`;
      }

      return '';
    },

    currentHiddenUp() {
      if (this.profile.hidden_up) {
        const date = moment(this.profile.hidden_up).format('lll');
        return `<strong v-html="rawHtml">Hasta:</strong> ${date}`;
      }

      return null;
    },

  },

  watch: {
    errorsFormChangePassword(currentErrorsForm) {
      this.$refs['observer-form-change-password'].reset();
      const errors = {};
      for (const field in currentErrorsForm) {
        errors[field] = [];
        for (const i_error in currentErrorsForm[field]) {
          errors[field].push(currentErrorsForm[field][i_error]);
        }
      }
      console.log(
        'Seteamos errores',
        errors,
      );
      this.$refs['observer-form-change-password'].setErrors(errors);
    },

    changePasswordStatus(status) {
      if (status === consts.REQUEST_COMPLETE_SUCCESSFULLY) {
        // Limpiamos campos
        Object.keys(this.form_change_password).map((k, i) => { this.form_change_password[k] = ''; });
        this.$refs['observer-form-change-password'].reset();
        this.showMessage('La contraseña ha sido actualizada');
      }
    },

    showProfileStatus(status) {
      if (status === consts.REQUEST_COMPLETE_SUCCESSFULLY) {
        this.showMessage('Su perfil ahora es visible');
      }
    },

    hideProfileTimeStatus(status) {
      if (status === consts.REQUEST_COMPLETE_SUCCESSFULLY) {
        this.showMessage('Perfil actualizado.');
        this.hidden_profile_time = null;
      }
    },

  },

  methods: {

    changePassword() {
      this.$store.dispatch('user/changePassword', this.form_change_password);
    },

    hideProfile() {
      this.$store.dispatch('user/hideProfile', this.hidden_profile_time);
    },

    showProfile() {
      this.$store.dispatch('user/showProfile');
    },

    cancelChangePassword() {
      Object.keys(this.form_change_password).map((k, i) => { this.form_change_password[k] = ''; });
      this.closePanel(0);
    },

    cancelHideProfile() {
      this.hidden_profile_time = null;
      this.closePanel(1);
    },

    closePanel(panelToclose) {
      this.panels = [];
      // this.panels = this.panels.filter((panel) => panel !== panelToclose);
    },

    showMessage(message) {
      this.snackbar = {
        status: true,
        text: message,
      };
    },

    checkHiddenUp() {

      const dateHiddenUp = moment(this.profile.hidden_up);
      if (!dateHiddenUp.isValid) {
        return;
      }

      if (dateHiddenUp.isBefore(moment())) {
        this.$store.commit('auth/setHiddenUpProfile', null, { root: true });
      }
    },

  },

  components: {

  },

};
