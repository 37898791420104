
export default {
  filters: {
    location: null,
    service: null,
    work_equipment: [],
    association: null,
    gender: null,
    languages: [],
    age_range: [],
  },
};
