/*
|-------------------------------------------------------------------------------
| modules/users/users/getters.js
|-------------------------------------------------------------------------------
| The getters Vuex for search module
*/

import _default from "vuex";

export default {

  getSearchUsersStatusLoad(state) {
    return state.userSearchStatusLoad;
  },

  /**
   * Get searched users from store
   * @param {oobject} state
   *
   * @return {array}
   */
  getUsers(state) {
    return _.has(state.users, 'data') ? state.users.data : null;
  },

  // Obtiene metainformación de la busqueda
  getMetaUsers(state) {
    return state.users ? state.users : null;
  },

  getFirstSearch(state) {
    return state.firstSearch;
  },

};
