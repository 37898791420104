/*
|-------------------------------------------------------------------------------
| API api/languages.js
|-------------------------------------------------------------------------------
| The api for managment languages.
*/

export default {

  /**
	 * Get the available languages.
	 *
	 * @return     {Promise}
	 */
  getLanguages() {
    return axios.get('/api/languages');
  },

};
