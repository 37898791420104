/*
|-------------------------------------------------------------------------------
| API api/associatioons.js
|-------------------------------------------------------------------------------
| The api for managment associations.
*/

export default {

  /**
	 * Obtiene las asociaciones disponibles.
	 *
	 * @return     {Promise}
	 */
  getAssociations() {
    return axios.get('/api/associations');
  },

};
