export default {
  locations: {
    name_admin_area_level: {
      spain: {
        0: 'municipio',
        1: 'provincia',
        2: 'comunidad autónoma',
      },
    },
    ordinal_work_area: {
      1: 'primera', // Primera área de trabajo
      2: 'segunda', // Segunda   ""
      //
    },
  },
  work_areas: 'Áreas de trabajo',
  info_contact: 'Información de contacto',

  validations: {
    restrict_level_priority: 'La {ordinal_work_area} área de trabajo no puede tener un área administrativo superior de {name_admin_area_level}',
  },

  contact_means: {
    type_contacts: {
      1: 'Personal',
      2: 'Trabajo',
    },
  },

  services: {
    name: 'Servicios',
    select: 'Seleccionar servicios',
    selected: '{n} servicio seleccionado | {n} servicios seleccionados',
  },

  languages: {
    name: 'Idiomas',
    select: 'Indicar idiomas',
    selected: '{n} idioma seleccionado | {n} idiomas seleccionados',
  },

  work_equipment: {
    name: 'Equipo de trabajo',
    select: 'Modificar equipo de trabajo',
    selected: '{n} herramienta seleccionada | {n} herramientas seleccionadas',
  },

  associations: {
    name: 'Asociaciones',
    select: 'Modificar asociaciones',
    selected: '{n} asociación seleccionada | {n} asociaciones seleccionadas',
  },

  race: {
    name: 'Raza',
    select: 'Seleccionar raza',
  },

  success_approved_user: 'El usuario ha sido aprobado con éxito.',

  idioms: {
    es: 'Español',
    en: 'Ingles',
    it: 'Italiano',
    de: 'Alemán',
    pt: 'Portugues',
    fr: 'Francés',
  },

};
