import draggable from 'vuedraggable';
import NotifyStatusAsyncMixin from '../../../mixins/NotifyStatusAsyncMixin/notify_status_async_mixin.js';

import WorkAreaSelectComponent from '../../../components/WorkAreaSelectComponent/WorkAreaSelectComponent';
import ContactMeanSelectComponent from '../../../components/ContactMeanSelectComponent/ContactMeanSelectComponent';
import EditDataComponent from '../../../components/EditDataComponent/EditDataComponent';
import * as consts from '../../../constants.js';

import {
  mdiClose,
} from '@mdi/js';

/**
 * Contiene dos estados, indicando que un formulario concreto está o no en modo
 * de edición.
 * NOTA: En el momento que se envían nuevos datos al servidor, el formulario
 * pasa a "no edición".
 */
const statusEdit = {
  editForm: {
    edit: false,
    icon: 'pen',
    new_data: null,
  },
  editingForm: {
    edit: true,
    icon: 'check',
    new_data: null,
  },
};

const MAX_WORK_AREAS = 7;

/**
 * Indica el mayor nivel de área adminstritativo que puede tener un área de trabajo
 * según su prioridad.
 * La clave indica la prioridad y el valor el nivel.
 *
 * @type       {<type>}
 */
const maxLevelPriority = {
  1: 1,
};

export default {
  name: 'profile',
  mixins: [
    NotifyStatusAsyncMixin,
  ],
  data: () => ({

    icons: {
      mdiClose,
    },

    selectedWorkArea: false,
    editData: {
      work_areas: statusEdit.editForm,
      contact_means: statusEdit.editForm,
      services: statusEdit.editForm,
      work_equipment: statusEdit.editForm,
      associations: statusEdit.editForm,
      languages: statusEdit.editForm,
      race: statusEdit.editForm,
      office_name: statusEdit.editForm,
      more_information: statusEdit.editForm,
      age_range: statusEdit.editForm,
    },
    positionsEditDataComponent: {
      work_areas: null,
      contact_means: null,
      services: null,
      work_equipment: null,
      associations: null,
      languages: null,
      race: null,
      office_name: null,
      more_information: null,
      age_range: null,
    },
    showStatusEditing: {
      work_areas: false,
    },

    searchIdiom: null,
    filtersLanguages: [],

    // Disparador falso que ponemos en propiedades computadas,
    // para forzarlo cambiando el valor (++).
    dummyTriggerChangeData: 1,
    snackbar: {
      status: false,
      msg: '',
    },
  }),

  created() {
    this.$store.dispatch('user/unsetUser');
    this.getUser();
  },

  mounted() {
    // https://stackoverflow.com/questions/49378649/saving-vuex-state-on-mounted-lifecycle
    this.$options.originalStateUser = null;

    // https://forum.vuejs.org/t/vuex-deep-watch-triggering/50938/6
    // Observa el status cuando se está guardando datos en el backend,
    const attrsProfile = [
      'work_areas',
      'contact_means',
      'services',
      'work_equipment',
      'associations',
      'languages',
      'race',
      'office_name',
      'more_information',
      'age_range',
    ];

    for (let i = 0; i < attrsProfile.length; i++) {
      const indexProfile = attrsProfile[i];
      this.watchStorePropertiesProfile(indexProfile);
    }
  },

  computed: {
    isAuthenticatedUser() {
      return this.$store.getters['user/isAuthenticatedUser'];
    },
    profile() {
      const profile = this.$store.getters['user/getUser'];
      if (this.$options.originalStateUser == null) {
        this.$options.originalStateUser = _.cloneDeep(profile);
      }
      return profile;
    },

    servicesProfile: {
      get() {
        return this.profile.services;
      },
      set(value) {
        this.$store.commit('user/setServices', value);
      },
    },

    associationsProfile: {
      get() {
        return this.profile.associations;
      },
      set(value) {
        this.$store.commit('user/setAssociations', value);
      },
    },

    languagesProfile: {
      get() {
        return this.profile.languages;
      },
      set(value) {
        this.$store.commit('user/setLanguages', value);
      },
    },

    workEquipmentProfile: {
      get() {
        return this.profile.work_equipment;
      },
      set(value) {
        this.$store.commit('user/setWorkEquipment', value);
      },
    },

    raceProfile: {
      get() {
        return this.profile.race;
      },
      set(value) {
        this.$store.commit('user/setRace', value);
      },
    },

    moreInformationProfile: {
      get() {
        return this.profile.more_information;
      },
      set(value) {
        this.$store.commit('user/setMoreInformation', value);
      },
    },

    officeNameProfile: {
      get() {
        return this.profile.office_name;
      },
      set(value) {
        this.$store.commit('user/setOfficeName', value);
      },
    },

    ageRangeProfile: {
      get() {
        return this.profile.age_range;
      },
      set(value) {
        this.$store.commit('user/setAgeRange', value); 
      },
    },

    /**
     * Indica el estado en el que se encuentra la actualización de
     * datos en el back.
     *
     * @return     {object}
     */
    dataManagmentStatus() {
      return this.$store.getters['user/getDataManagmentStatus'];
    },

    /**
     * Un array con las áreas de trabajo definidas por el usuario.
     *
     * @return     {Array}  { description_of_the_return_value }
     */
    workAreas: {
      get() {
        return this.$store.getters['user/getUser'].work_areas;
      },
      set(value) {
        this.$store.commit('user/setWorkAreas', value);
      },
    },

    reachedMaxWorkAras() {
      if (!Array.isArray(this.workAreas)) {
        return false;
      }

      return MAX_WORK_AREAS <= this.workAreas.length;
    },

    /**
     * Comprueba si los datos originales difiren con los cambios
     * que ha echo el usuario sobre el formulario.
     *
     * @return     {Object}  { description_of_the_return_value }
     */
    changeData() {
      this.dummyTriggerChangeData;

      return {
        work_areas: !_.isEqual(this.workAreas, this.$options.originalStateUser.work_areas),
        contact_means: !_.isEqual(this.profile.contact_means, this.$options.originalStateUser.contact_means),
        services: !_.isEqual(this.profile.services, this.$options.originalStateUser.services),
        work_equipment: !_.isEqual(this.profile.work_equipment, this.$options.originalStateUser.work_equipment),
        associations: !_.isEqual(this.profile.associations, this.$options.originalStateUser.associations),
        languages: !_.isEqual(this.profile.languages, this.$options.originalStateUser.languages),
        race: !_.isEqual(this.profile.race, this.$options.originalStateUser.race),
        office_name: !_.isEqual(this.profile.office_name, this.$options.originalStateUser.office_name),
        more_information: !_.isEqual(this.profile.more_information, this.$options.originalStateUser.more_information),
        age_range: !_.isEqual(this.profile.age_range, this.$options.originalStateUser.age_range),
      };
    },

    /**
     * Comprueba que las áreas de trabajo en cada nivel de prioridad tenga su nivel
     * de área de administración correcto.
     * En caso contrario se ocultará el botón de guardar.
     *
     * Ponemo este método como método y no como propiedad computada, ya que el error
     * de tener un nivel de área de trabajo en una prioridad que no corresponde se dará
     * cuando se hacen operaciones como la de eliminar y el reordenamiento que este lleva.
     *
     */
    checkLevelPriorityWorkAreas() {
      const workAreas = this.$store.getters['user/getUser'].work_areas;
      let workArea; let
        priority;
      for (let i = 0; i < workAreas.length; i++) {
        priority = parseInt(i) + 1;
        if (!this.checkLevelPriorityWorkArea(priority, workAreas[i].admin_area.level)) {
          const { level } = workAreas[i].admin_area;
          this.$notify({
            group: 'common',
            text: this.$t(
              'validations.restrict_level_priority', {
                ordinal_work_area: this.$t(`locations.ordinal_work_area[${priority}]`),
                name_admin_area_level: this.$t(`locations.name_admin_area_level.spain[${parseInt(level) - 1}]`),
              },
            ),
            type: 'warn',
            duration: -1,
          });
          return false;
        }
      }

      this.$notify({
        group: 'common',
        clean: true,
      });

      return true;
    },

    /**
     * Servicios que puede prestar un usuario
     */
    services() {
      return this.$store.getters['services/getServices'];
    },

    userStatusLoad() {
      return this.$store.getters['user/getUserStatusLoad'];
    },

    // Herramientas de trabajo que puede tener un usuario.
    workEquipment() {
      return this.$store.getters['workEquipment/getWorkEquipment'];
    },

    // Asociaciones a las que puede pertenecer un usuario.
    associations() {
      return this.$store.getters['associations/getAssociations'];
    },

    // Idiomas que puede hablar un usuario.
    languages() {
      return this.$store.getters['languages/getLanguages'];
    },

    //Razas
    races() {
      return this.$store.getters['races/getRaces'];
    },

    ageRanges() {
      return this.$store.getters['ages/getAgeRanges'];
    },

    isLoading() {
      return this.userStatusLoad === consts.REQUEST_HAS_STARTED;
    },

    positionEditDataComponent() {
      Object.keys(this.positionsEditDataComponent).forEach((key) => {
        this.positionsEditDataComponent[key] = {
          'text-right': !this.editData[key].edit || this.$vuetify.breakpoint.lgAndUp,
          'text-center': this.editData[key].edit && this.$vuetify.breakpoint.mdAndDown,
        };
      });

      return this.positionsEditDataComponent;
    },

  },

  watch: {
    /**
     * Cambiamos solo el parámetro de la url, pero no el componente.
     * @see https://router.vuejs.org/guide/essentials/dynamic-matching.html#reacting-to-params-changes
     *
     * @param      {<type>}  to      { parameter_description }
     * @param      {<type>}  from    The from
     */
    $route(to, from) {
      this.getUser();
    },

    searchIdiom(val) {
      val && val.length > 2 && this.querySelectionsLanguges(val);
    },

    'editData.services': function (status) {
      if (status.edit && (!Array.isArray(this.services) || !this.services.length)) {
        console.log('No tenemos servicios, los obtenemos');
        this.$store.dispatch('services/getServices');
      }
    },

    'editData.work_equipment': function (status) {
      if (status.edit && (!Array.isArray(this.workEquipment) || !this.workEquipment.length)) {
        this.$store.dispatch('workEquipment/getWorkEquipment');
      }
    },

    'editData.associations': function (status) {
      if (status.edit && (!Array.isArray(this.associations) || !this.associations.length)) {
        this.$store.dispatch('associations/getAssociations');
      }
    },

    'editData.languages': function (status) {
      if (status.edit && (!Array.isArray(this.languages) || !this.languages.length)) {
        this.$store.dispatch('languages/getLanguages');
      }
    },

    'editData.race': function (status) {
      if (status.edit && (!Array.isArray(this.races) || !this.races.length)) {
        this.$store.dispatch('races/getRaces');
      }
    },

    'editData.age_range': function (status) {
      if (status.edit && (!Array.isArray(this.ageRanges) || !this.ageRanges.length)) {
        this.$store.dispatch('ages/getAgeRanges');
      }
    },

    userStatusLoad(status) {
      if (status == consts.REQUEST_COMPLETE_UNSUCCESSFULLY) {
        this.$router.push({
          name: 'Users',
        });
      }
    },
  },

  methods: {
    getUser() {
      this.$store.dispatch('user/getUser', this.$route.params.id);
    },

    /**
     * Hace una parte (definada por index) de el perfil de usuario editable.
     * Hace una copia de los datos de el usuario para comparar después
     * si han sido cambiados.
     *
     * @param      {string}  index La parte de el formulario.
     */
    enableForm(index) {
      // Ponemos el formulario en modo de edición
      this.editData[index] = statusEdit.editingForm;
    },

    /**
     * Cancela la edición de un parte de el perfil de usuario,
     *
     * @param      {string}  index La parte de el formulario.
     */
    cancelForm(index) {
      const indexFn = _.pascalCase(index);
      this.$store.commit(`users/set${indexFn}`, this.$options.originalStateUser[index]);
      this.editData[index] = statusEdit.editForm;
    },

    /**
     * Añade una nueva área al store del perfil
     */
    addWorkArea() { 

      // Comprobamos si ya está añadido

      const newWorkArea = {
        country: this.selectedWorkArea.country,
        admin_area: {
          id: this.selectedWorkArea.adminAreaId,
          level: this.selectedWorkArea.adminAreaLevel,
        },
        full_name: this.selectedWorkArea.fullNameLocation,
      };

      let exists = false;
      this.workAreas.forEach((workArea) => {
        if (newWorkArea.country === workArea.country &&
            newWorkArea.admin_area.level === workArea.admin_area.level &&
            newWorkArea.admin_area.id === workArea.admin_area.id
        ) {
          exists = true;
        }
      });

      if (exists) {
        this.snackbar.status = true;
        this.snackbar.msg = 'La localización elegida ya está añadida';
        return;
      }

      this.$store.dispatch('user/pushWorkArea', newWorkArea);

      this.$refs.workArea.resetWorkArea();
    },

    // Elimina un área de trabajo en el store
    removeWorkArea(index) {
      this.$store.dispatch('user/removeWorkArea', index);
    },

    // Elimina un contacto en el store
    removeContactMean(index, driverIndex) {
      this.$store.dispatch('user/removeContactMean', { index, driverIndex });
    },

    // Elimina un servicio en el store
    removeService(index) {
      this.$store.dispatch('user/removeService', index);
    },

    // Elimina un equipo de trabajo en el store
    removeWorkEquipment(index) {
      this.$store.dispatch('user/removeWorkEquipment', index);
    },

    // Elimina una asociación en el store
    removeAssociation(index) {
      this.$store.dispatch('user/removeAssociation', index);
    },

    // Elimina un lenguaje del store
    removeLanguage(index) {
      this.$store.dispatch('user/removeLanguage', index);
    },

    /**
     * Ejecuta acción vuex para guardar una parte del perfil de usuario.
     *
     * @param      {string}  index
     */
    saveDataProfile(index) {
      this.editData[index] = statusEdit.editForm;
      this.showStatusEditing[index] = true;
      this.$store.dispatch('user/saveDataProfile', index);
    },

    // Se ha seleccionado una localización, independientemente de el área administrativa.
    selectedLocation(selectedLocation) {
      /* console.log('selectedLocation');
            console.log(selectedLocation); */
      this.selectedWorkArea = selectedLocation.currentAdminAreaLevel == null
        ? null
        : selectedLocation.newLocation;
    },

    /**
     * Según prioridad, el área administrativa puede tener un nivel de área administrativa limidado.
     *
     * @param      {<type>} El evento
     * @return     {bool}  Si es false no dejará arrastrar a la posición.
     */
    newSortWorkAreas(e) {
      const futurePriority = parseInt(e.draggedContext.futureIndex) + 1;
      return !maxLevelPriority.hasOwnProperty(futurePriority)
                    || (
                      maxLevelPriority.hasOwnProperty(futurePriority)
                        && maxLevelPriority[futurePriority] >= e.draggedContext.element.admin_area.level
                    );
    },

    /**
     * Comprueba que el nivel de un área de trabajo determinado, esté permitdo
     * para una prioridad dada.
     *
     * @param      {integer}  priority  The future priority
     * @param      {integer}  levelWorkArea   The level work area
     *
     * @return     {boolean}
     */
    checkLevelPriorityWorkArea(priority, levelWorkArea) {
      return !maxLevelPriority.hasOwnProperty(priority)
                    || (
                      maxLevelPriority.hasOwnProperty(priority)
                        && maxLevelPriority[priority] >= levelWorkArea
                    );
    },

    /**
     * Añade un nuevo contacto al store
     *
     * @param      {<type>}  contactMean  The contact mean
     */
    addContactMean(contactMean) {
      const newContactMean = {
        type: contactMean.type_contact.type_contact,
        id_driver: contactMean.driver_contact_type.id_driver,
        value: contactMean.value_contact,
      };

      this.$store.dispatch(
        'user/pushContactMean',
        newContactMean,
      );

      this.$refs.contactMean.resetContactSelect();
    },

    querySelectionsLanguges(v) {
      this.filtersLanguages = this.languages.filter(l => (l.idiom || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1);
    },

    /**
     * Indica en el store hacer un watch de los estados de actualización para
     * una propiedad de el usuario
     * @param {String} indexProfile 
     */
    watchStorePropertiesProfile(indexProfile) {
      this.$store.watch(
        (state, getters) => getters['user/getDataManagmentStatus'][indexProfile],
        (status) => {
          this.changedStatusEditData(status);
          if (status === consts.REQUEST_COMPLETE_SUCCESSFULLY) {
            this.$options.originalStateUser[indexProfile] = _.cloneDeep(this.profile[indexProfile]);
            this.dummyTriggerChangeData ++;
          } else {
            const indexFn = _.pascalCase(indexProfile);
            this.$store.commit(`users/set${indexFn}`, this.$options.originalStateUser[indexProfile]);
          }
        },
      );
    },

  },

  components: {
    WorkAreaSelectComponent,
    ContactMeanSelectComponent,
    EditDataComponent,
    draggable,
  },

};
