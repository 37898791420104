/*
|-------------------------------------------------------------------------------
| modules/sercvies/actions.js
|-------------------------------------------------------------------------------
| The actions Vuex for services module
*/

import * as consts from '../../constants.js';
import SericesAPI from '../../api/services.js';

export default {
  /**
     * Obtiene los servicios
     *
     * @param      {int}    id
     */
  async getServices({ dispatch, commit, getters }) {
    commit('setServicesStatusLoad', consts.REQUEST_HAS_STARTED);

    let response;

    try {
      response = await SericesAPI.getServices();
    } catch (error) {
      console.log('Ha ocurrido un error');
      commit('setServicesStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      return;
    }

    console.log('Response: ', response);

    commit('setServices', response.data);
    commit('setServicesStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
  },

};
