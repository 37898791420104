var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.registeredUser.status)?_c('div',[_c('ValidationObserver',{ref:"observer-form-register",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:50","name":"nombre","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nombre*","type":"text","error-messages":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:3|max:50","name":"primer apellido","vid":"surname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Primer apellido*","type":"text","error-messages":errors[0]},model:{value:(_vm.form.surname),callback:function ($$v) {_vm.$set(_vm.form, "surname", $$v)},expression:"form.surname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"min:3|max:50","name":"segundo apellido","vid":"second_surname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Segundo apellido","type":"text","error-messages":errors[0]},model:{value:(_vm.form.second_surname),callback:function ($$v) {_vm.$set(_vm.form, "second_surname", $$v)},expression:"form.second_surname"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email","mode":"lazy","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email*","type":"text","error-messages":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|min:6|max:30","name":"contraseña","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password","label":"Contraseña*","type":"password","error-messages":errors[0]},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|confirmed:password","name":"confirmación contraseña","vid":"password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Repetir contraseña*","type":"password","error-messages":errors[0]},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|license_number:1,8000","name":"número de licencia*","vid":"license_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Número de licencia","type":"text","error-messages":errors[0]},model:{value:(_vm.form.license_number),callback:function ($$v) {_vm.$set(_vm.form, "license_number", $$v)},expression:"form.license_number"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"name":"teléfono","vid":"tlf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Teléfono","type":"text","error-messages":errors[0]},model:{value:(_vm.form.tlf),callback:function ($$v) {_vm.$set(_vm.form, "tlf", $$v)},expression:"form.tlf"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"género","vid":"gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{attrs:{"row":"","error-messages":errors[0]},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_c('v-radio',{attrs:{"label":"Hombre","value":"m"}}),_c('v-radio',{attrs:{"label":"Mujer","value":"f"}})],1)]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',{attrs:{"align":"center","justify":"space-around"}},[_c('v-col',{attrs:{"cols":"10","sm":"11"}},[_c('validation-provider',{attrs:{"rules":"required|image|size:8000","name":"diligencia libro de registro","vid":"record_book_diligence"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-file-input',{attrs:{"show-size":"","prepend-icon":'',"prepend-inner-icon":"mdi-file","label":"Adjuntar diligencia del libro registro*","error-messages":errors[0]},model:{value:(_vm.form.record_book_diligence),callback:function ($$v) {_vm.$set(_vm.form, "record_book_diligence", $$v)},expression:"form.record_book_diligence"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"2","sm":"1"}},[_c('v-icon',{attrs:{"size":"22"},on:{"click":function($event){return _vm.showDialog('recordBookDiligence')}}},[_vm._v(" mdi-help-circle ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h6',{staticClass:"subtitle-1"},[_vm._v(" Área de trabajo "),_c('v-icon',{attrs:{"size":"22"},on:{"click":function($event){return _vm.showDialog('workAreaInfo')}}},[_vm._v(" mdi-help-circle ")])],1)]),_c('v-col',{attrs:{"cols":"12","sm":"6"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"data-vv-name":"admin_area_level_1","data-vv-as":"provincia","label":"Provincia","items":_vm.adminAreasLevel1,"item-text":"name","item-value":"id_area"},on:{"change":_vm.getLocalities},model:{value:(_vm.form.adminAreaLevel1),callback:function ($$v) {_vm.$set(_vm.form, "adminAreaLevel1", $$v)},expression:"form.adminAreaLevel1"}},[_c('v-tooltip',{attrs:{"slot":"prepend","bottom":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"22"}},on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v("Para seleccionar la provincia entera, no seleccione municipio.")])])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{staticClass:"text-truncate",attrs:{"data-vv-name":"admin_area_level_0","disabled":!_vm.hasLocalities,"data-vv-as":"municipio","label":"Municipio","items":_vm.localities,"item-text":"name","item-value":"id_area"},model:{value:(_vm.form.locality),callback:function ($$v) {_vm.$set(_vm.form, "locality", $$v)},expression:"form.locality"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":{ required: { allowFalse: false } },"vid":"accept_terms","name":"Términos y Condiciones"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{attrs:{"name":"Términos y condiciones","error-messages":errors[0],"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" He leido y acepto los "),_c('router-link',{attrs:{"target":"_blank","to":{name:'LegalTerms'}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" Términos y Condiciones* ")])],1)]},proxy:true}],null,true),model:{value:(_vm.form.accept_terms),callback:function ($$v) {_vm.$set(_vm.form, "accept_terms", $$v)},expression:"form.accept_terms"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('vue-recaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.$getConst('RECAPTCHA_V2_SITE_KEY')},on:{"verify":_vm.onCaptchaVerified,"expired":_vm.onCaptchaExpired}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"disabled":invalid,"loading":_vm.makingRequest},on:{"click":_vm.createNewUser}},[_vm._v(" Registrarse ")])],1),_c('v-col',{staticClass:"error--text"},[_c('validation-provider',{attrs:{"rules":"required","name":"captcha","vid":"recaptcha_token"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_vm._l((errors),function(error,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(error)+" ")])}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.recaptcha_token),expression:"form.recaptcha_token"}],attrs:{"type":"hidden","name":""},domProps:{"value":(_vm.form.recaptcha_token)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "recaptcha_token", $event.target.value)}}})]}}],null,true)})],1)],1)],1)],1)],1)],1)]}}],null,false,386194119)})],1):_c('div',[_c('v-container',[_c('v-card',{staticClass:"text-center"},[_c('v-card-text',{attrs:{"mb":"4"}},[_c('v-icon',{attrs:{"color":"success","size":"60"}},[_vm._v(" mdi-check-circle-outline ")])],1),_c('v-card-text',[_c('p',{staticClass:"body-1"},[_vm._v(" "+_vm._s(_vm.registeredUser.msg)+" ")])])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogInfo),callback:function ($$v) {_vm.dialogInfo=$$v},expression:"dialogInfo"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline title-dialog",attrs:{"primary-title":""}},[_vm._v(" "+_vm._s(_vm.dialog.title)+" ")]),_c('v-card-text',{staticClass:"body-dialog",domProps:{"innerHTML":_vm._s(_vm.dialog.msg)}}),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.dialogInfo = false}}},[_vm._v(" Aceptar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }