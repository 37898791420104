/*
|-------------------------------------------------------------------------------
| modules/ages/getters.js
|-------------------------------------------------------------------------------
| The getters Vuex for ages module
*/

export default {

  /**
	 * Obtiene el estado en el que se encuentra el proceso de obtención de los
	 * rangos de edad.
	 */
  getDataAgeRangesStatus(state) {
    return state.dataAgeRangesStatus;
  },

  /**
	 * Obtiene los rangos que puede prestar un usuario.
	 *
	 * @param {object} state
	 */
  getAgeRanges(state) {
    return state.age_ranges;
  },

};
