/*
|-------------------------------------------------------------------------------
| modules/user/actions.js
|-------------------------------------------------------------------------------
| The actions Vuex for users module
*/

import * as consts from '@/constants.js';
import UserAPI from '@/api/user.js';

export default {
  /**
   * Obtiene el perfil de un usuario. Este puede ser obtenido de diferentes
   * fuentes y en el siguiente orden de un array precargado que tengamos,
   * (un slider de usuarios relacionados por ejemplo),
   * nuestro propio perfil o tener que hacer una llamada al servidor si no lo
   * tenemos presente.
   *
   * @param      {int}    id
   */
  getUser({
    dispatch, commit, getters, rootGetters,
  }, id) {
    commit('setUserStatusLoad', consts.REQUEST_HAS_STARTED);
    UserAPI.getUser(id)
      .then((response) => {
        if (!response.data.detective) {
          console.log('No se ha localidado ningún usuario');
          commit('setUserStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
          return;
        }

        commit('setUser', response.data.detective);
        commit('setUserStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
      })
      .catch((response) => {
        commit('setUserStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      });
  },

  changePassword({ commit }, dataChangePassword) {
    commit('setChangePasswordStatus', consts.REQUEST_HAS_STARTED);
    UserAPI.changePassword(dataChangePassword)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          commit('setChangePasswordStatus', consts.REQUEST_COMPLETE_SUCCESSFULLY);
        } else {
          if (response.status >= 422) {
            commit('setErrorsChangePassword', response.data.errors);
          }
          commit('setChangePasswordStatus', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
        }
      })
      .catch((/* response */) => {
        commit('setChangePasswordStatus', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      });
  },

  hideProfile({ commit }, hiddenProfileTime) {
    commit('setHideProfileTimeStatus', consts.REQUEST_HAS_STARTED);
    UserAPI.hideProfile(hiddenProfileTime)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // Ponemos el hidden_up al profile correspondiente.
          commit('auth/setHiddenUpProfile', response.data, { root: true });
          commit('setHideProfileTimeStatus', consts.REQUEST_COMPLETE_SUCCESSFULLY);
          console.log('Se ha ocultado');
        } else {
          console.log('No se ha ocultado');
          commit('setHideProfileTimeStatus', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
        }
      })
      .catch((/* response */) => {
        commit('setHideProfileTimeStatus', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      });
  },

  showProfile({ commit }) {
    commit('setShowProfileStatus', consts.REQUEST_HAS_STARTED);
    UserAPI.showProfile()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          // Ponemos el hidden_up al profile correspondiente.
          commit('setShowProfileStatus', consts.REQUEST_COMPLETE_SUCCESSFULLY);
          commit('auth/setHiddenUpProfile', null, { root: true });
          console.log('Perfil visible', response);
        } else {
          console.log('No se ha podido hacer visible');
          commit('setShowProfileStatus', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
        }
      })
      .catch((/* response */) => {
        commit('setShowProfileStatus', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      });
  },

  unsetUser({
    dispatch, commit, getters, rootGetters,
  }, id) {
    commit('setUser', null);
  },
  setUserStatusLoad({ commit }, status) {
    commit('setUserStatusLoad', status);
  },
  resetProfile({ commit }) {
    commit('setUserStatusLoad', consts.REQUEST_WITHOUT_STARTING);
    commit('setWorkAreasLoad', consts.REQUEST_WITHOUT_STARTING);
  },

  /**
   * Añade una nueva área de trabajo.
   * Tener en cuenta que el objeto añadido puede ser diferente a como nos lo devuelve la api.
   * El objeto añadido lleva los datos necesarios para poder guardarlo en ddbb,
   * Importante que tanto el objeto devuelto por la api y el que añadimos, tengan
   * las mismas propiedades que se utilizen para después guardar en el backend.    *
   *
   * @param      {object}  newWorkArea
   */
  pushWorkArea({ commit, state }, workArea) {
    const newWorkAreas = [...state.user.work_areas];
    newWorkAreas.push(workArea);
    commit('setWorkAreas', newWorkAreas);
    // state.user.work_areas.push(workArea);
  },

  /**
   * Elimina un área de trabajo de el store de perfil
   *
   * @param      {object}  state
   * @param      {integer}  index   El indice de la key a borrar de el array de áreas
   */
  removeWorkArea({ commit, state }, index) {
    const newWorkAreas = [...state.user.work_areas];
    newWorkAreas.splice(index, 1);
    commit('setWorkAreas', newWorkAreas);
  },

  /**
   * Elimina un servicio
   *
   * @param      {object}  state
   * @param      {integer}  index   El indice de la key a borrar de el array de servicios del usuario
   */
  removeService({ commit, state }, index) {
    const newService = [...state.user.services];
    newService.splice(index, 1);
    commit('setServices', newService);
  },

  /**
   * Elimina un equipo de trabajo
   *
   * @param      {object}  state
   * @param      {integer}  index   El indice de la key a borrar de el array de equipos de trabajo del usuario
   */
  removeWorkEquipment({ commit, state }, index) {
    const newWorkEquipment = [...state.user.work_equipment];
    newWorkEquipment.splice(index, 1);
    commit('setWorkEquipment', newWorkEquipment);
  },

  /**
   * Elimina una asociación
   *
   * @param      {object}  state
   * @param      {integer}  index   El indice de la key a borrar del array de asociaciones
   *                                del usuario
   */
  removeAssociation({ commit, state }, index) {
    const newAssociations = [...state.user.associations];
    newAssociations.splice(index, 1);
    commit('setAssociations', newAssociations);
  },

  /**
   * Elimina un idioma
   *
   * @param      {object}  state
   * @param      {integer}  index   El indice de la key a borrar del array de idiomas
   *                                del usuario
   */
  removeLanguage({ commit, state }, index) {
    const newLanguages = [...state.user.languages];
    newLanguages.splice(index, 1);
    commit('setLanguages', newLanguages);
  },


  /**
   * Estable unas áreas de trabajo
   *
   * @param      {array}    workAreas    The work areas
   */
  setWorkArea({ commit }, workAreas) {
    commit('setWorkAreas', workAreas);
  },

  /**
   * Añade un nuevo contacto al store
   *
   * @param      {object}    contactMean  The contact mean
   * @param      {number}    id_driver    The identifier driver
   */
  pushContactMean({ commit, state }, contactMean) {
    const contactMeans = _.cloneDeep(state.user.contact_means);
    const contactMeanDriver = contactMean.id_driver == 0 ? 'emails' : 'tlfs';
    delete contactMean.id_driver;
    contactMeans[contactMeanDriver].push(contactMean);
    commit('setContactMeans', contactMeans);
  },

  /**
   * Elimina un contacto del store
   *
   * @param      {numeric}    dataIndex
   *  {
   *      index,
   *      driverIndex
   *  }
   */
  removeContactMean({ commit, state }, dataIndex) {
    const contactMeans = { ...state.user.contact_means };
    const contactMeansDriver = [...contactMeans[dataIndex.driverIndex]];
    contactMeansDriver.splice(dataIndex.index, 1);
    contactMeans[dataIndex.driverIndex] = contactMeansDriver;
    commit('setContactMeans', contactMeans);
  },

  /**
   * Envía petición al servidor para guardar una parte del perfil de usuario.
   * Los datos los obtendrá del store.state.
   *
   * @param      {string}    key La parte de el perfil de usuario a guardar
   */
  async saveDataProfile({ commit, state }, key) {
    commit('setDataManagmentStatus', {
      status: consts.REQUEST_HAS_STARTED,
      key,
    });
    const keyPC = _.pascalCase(key);
    try {
      const response = await UserAPI[`save${keyPC}`](_.cloneDeep(state.user[key]));
      console.log('response');
      console.log(response);
      if (response.status >= 200 && response.status < 300 && _.has(response, 'data.data')) {
        commit(`set${keyPC}`, response.data.data);
        commit('setDataManagmentStatus', {
          status: consts.REQUEST_COMPLETE_SUCCESSFULLY,
          key,
        });
      } else {
        commit('setDataManagmentStatus', {
          status: consts.REQUEST_COMPLETE_UNSUCCESSFULLY,
          key,
        });
      }
    } catch (error) {
      commit('setDataManagmentStatus', {
        status: consts.REQUEST_COMPLETE_UNSUCCESSFULLY,
        key,
      });
    }
  },

};
