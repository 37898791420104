/*
|-------------------------------------------------------------------------------
| API modules/ages/index.js
|-------------------------------------------------------------------------------
| The Vuex data store for ages
*/

import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './state';

export const ages = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
