export default {
  name: 'notifications-component',
  data: () => ({
    configNotification: null,
    breakpoint: null,
    configIcon: {
      success: {
        color: '#00dd00',
        icon: 'mdi-check-bold',
      },
      warn: {
        color: '#ffc107',
        icon: 'mdi-alert',
      },
    },
  }),

  created() {
    this.breakpoint = this.$vuetify.breakpoint;

    /**
		 * Creamos la configuración del notificador
		 */
    this.configNotification = {
      position: this.positionMyNotification(),
      duration: 12000, // 12s
      closeOnClick: true,
      width: this.widthMyNotification(),
    };
  },

  methods: {

    /**
		 * Obtiene la posición para colocar el notificador según el breakpoint actual.
		 */
    positionMyNotification() {
      return this.breakpoint.xsOnly ? 'bottom center' : 'top right';
    },

    widthMyNotification() {
      return this.breakpoint.xsOnly ? 350 : 400;
    },
  },

};
