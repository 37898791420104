import * as consts from '@/constants.js';


export default {

  data: () => ({
    // Data form
    form: {
      email: '',
      password: '',
    },

    form_recovery_password: {
      email: '',
    },

    key_verify: null,

    show_recovery_password: false,
  }),

  created() {
    // `this` points to the vm instance

    this.$store.dispatch('auth/setVerificationAccount', _.includes(this.$route.path, 'login/verify'));
  },

  computed: {
    makingRequest() {
      return this.$store.getters['auth/getAuthStatusLoad'] === consts.REQUEST_HAS_STARTED;
    },
    makingRecoveryPasswordRequest() {
      return this.$store.getters['auth/getRestorePasswordStatusLoad'] === consts.REQUEST_HAS_STARTED;
    },
    isRestoredPassword() {
      return this.$store.getters['auth/getRestorePasswordStatusLoad'] === consts.REQUEST_COMPLETE_SUCCESSFULLY;
    },
    restoredPassword() {
      return this.$store.getters['auth/getRestoredPassword'];
    },
    errorsForm() {
      return this.$store.getters['auth/getErrorsFormLogin'];
    },
    errorsFormRestorePassword() {
      return this.$store.getters['auth/getErrorsFormRestorePassword'];
    },
    isVerificationAccount() {
      return this.$store.getters['auth/getVerificationAccount'];
    },
    isAuthenticate() {
      return this.$store.getters['auth/getAuthStatusLoad'] === consts.REQUEST_COMPLETE_SUCCESSFULLY;
    },
    verifiedAccount() {
      return this.$store.getters['auth/getVerifiedAccount'];
    },
  },

  watch: {
    errorsForm(currentErrorsForm) {
      this.$refs['observer-form-login'].reset();
      console.log(currentErrorsForm);

      this.$refs['observer-form-login'].setErrors({
        general: currentErrorsForm,
      });
    },
    errorsFormRestorePassword(currentErrorsForm) {
      console.log(currentErrorsForm);
      this.$refs['observer-form-recovery-password'].reset();

      this.$refs['observer-form-recovery-password'].setErrors({
        general: currentErrorsForm,
      });
    },
    isAuthenticate(status) {
      if (status) {
        this.$router.push({
          name: 'OwnProfile',
        });
      }
    },

  },

  methods: {
    async submit() {
      this.$refs['observer-form-login'].reset();

      const isValid = await this.$refs['observer-form-login'].validate();
      if (!isValid) {
        return;
      }

      const credentials = {
        email: this.form.email,
        password: this.form.password,
      };

      if (this.isVerificationAccount) {
        credentials.key_verification = this.$route.params.key;
        credentials.hash = this.$route.params.hash;
      }

      this.$store.dispatch('auth/login', credentials);
    },

    /**
     * Envía al backend petición para restaurar contraseña
     */
    async submitRecoveryPassword() {
      this.$refs['observer-form-recovery-password'].reset();

      const isValid = await this.$refs['observer-form-recovery-password'].validate();
      if (!isValid) {
        return;
      }

      this.$store.dispatch('auth/restorePassword', this.form_recovery_password.email);
    },

  },
};
