/*
|-------------------------------------------------------------------------------
| API api/collaborations/collaboration.js
|-------------------------------------------------------------------------------
| The api for managment associations.
*/

export default {

  /**
   * Guarda una nueva petición colaboración
   *
   * @param      {Object}  collaboration  The new collaboration
   * @return     {Promise}
   */
  admin(collaboration) {
    const endPoint = collaboration.id
      ? `edit/${collaboration.id}`
      : 'create';
    return axios.post(`/api/collaborations/${endPoint}`, collaboration);
  },


  /**
   * Obtiene una petición de colaboración
   *
   * @param      {integer}  collaborationId
   * @return     {Promise}
   */
  get(collaborationId) {
    return axios.get(`/api/collaboration/${collaborationId}`);
  },

  /**
   * Deshabilita o habilita una colaboración
   *
   * @param      {integer}  collaborationId
   * @param      {boolean}  disable
   * @return     {Promise}
   */
  disable(collaborationId, disable) {
    return axios.post(`/api/collaboration/disable/${collaborationId}`, {
      disable: disable.toString(),
    });
  },

  /**
   * Deshabilita una colaboración
   *
   * @param      {integer}  collaborationId
   * @return     {Promise}
   */
  delete(collaborationId) {
    return axios.delete(`/api/collaboration/${collaborationId}`);
  },

};
