/*
|-------------------------------------------------------------------------------
| API api/auth.js
|-------------------------------------------------------------------------------
| The api for admin auth tokens
*/

export default {

  login(credentials) {
    return axios.post('/api/login', credentials);
  },

  loginVerifyEmail(credentials) {
    return axios.post('/api/login/verify-email', credentials);
  },

  restorePassword(email) {
    return axios.post('/api/password/email', {
      email: email,
    });
  },

  resetPassword(data) {
    return axios.post('/api/password/reset', data);
  },

  register(data) {
    return axios.post(
      '/api/register',
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },

  /* logout: function (credentials) {
		return axios.post('/login', credentials);
	}, */

};
