/*
|-------------------------------------------------------------------------------
| API modules/races/index.js
|-------------------------------------------------------------------------------
| The Vuex data store for races that may belong to the user
*/

import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './state';

export const races = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
