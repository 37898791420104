/*
|-------------------------------------------------------------------------------
| modules/users/users/mutations.js
|-------------------------------------------------------------------------------
| The mutations Vuex for search module
*/

export default {

  setSearchUsersStatusLoad(state, status) {
    state.userSearchStatusLoad = status;
  },

  setUsers(state, users) {
    state.users = users;
  },

  setFirstSearch(state) {
    state.firstSearch = true;
  },

};
