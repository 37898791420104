/*
|-------------------------------------------------------------------------------
| API modules/work_equipment/index.js
|-------------------------------------------------------------------------------
| The Vuex data store for users work equipment
*/

import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './state';

export const workEquipment = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
