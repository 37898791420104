import FooterComponent from '@/components/TemplatesComponent/FooterComponent';

export default {

  computed: {

    isAuthenticate() {
      return this.$store.getters['auth/getToken'] !== null;
    },

  },

  components: {
    FooterComponent,
  },

};
