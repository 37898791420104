import * as consts from '../../constants.js';

export default {

  data() {
    	return {
    		comingOut: null,
    	};
  },

  computed: {
    isLogoutApp() {
      return this.$store.getters['auth/getProfile'] === null;
    },
  },

  watch: {
    	isLogoutApp(status) {
    		if (status) {
    			this.$router.push('/');
    			this.comingOut = false;
    		}
    	},
  },

  methods: {
    	logoutApp() {
    		console.log('Cerramos sesión');
    		this.comingOut = true;
    		this.$store.dispatch('auth/logoutApp');
    	},
  },
};
