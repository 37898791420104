import VueRecaptcha from 'vue-recaptcha';
import * as consts from '@/constants.js';
import imgDiligence from '@/assets/img/666710236c1c2a6737d3eab822dc64b5.png';

export default {

  name: 'register-page',

  data: () => ({
    // Data form
    form: {
      name: '',
      email: '',
      surname: '',
      second_surname: '',
      gender: '',
      password: '',
      password_confirmation: '',
      license_number: '',
      tlf: '',
      adminAreaLevel1: '',
      locality: '',
      country: 'ES',
      recaptcha_token: null,
      accept_terms: false,
      record_book_diligence: null,
    },

    dialog: {
      title: '',
      msg: '',
    },

    dialogs: {
      workAreaInfo: {
        title: 'Áreas de trabajo',
        msg: 'Para aparecer en los resultados de búsqueda deberá establecer un área de trabajo. En su perfil podrá establecer más lugares y áreas de trabajo, asignándole una prioridad a cada uno en función del desplazamiento.',
      },
      recordBookDiligence: {
        title: 'Adjuntar diligencia del libro registro',
        msg: `Desde Detecfinder, aplicación de colaboración de detectives privados, se quiere garantizar que todos los usuarios registrados sean detectives en posesión de su Tarjeta de Identidad Profesional y, además, que tengan dado de alta su despacho en la Dirección General de la Policía, tal y como se exige por ley para desempeñar este trabajo de forma legal.
        Por ello te pedimos, que envíes escaneada la primera página de tu libro registro, es decir, la diligencia del libro registro, donde figura tu Identidad, tu TIP y queda claro que tienes dado de alta tu despacho. Esto juega en favor de todos nosotros y de la profesión.
        <img style="max-width:100%;" class="mt-5" src="${imgDiligence}">`,
      },
    },

    // Config page
    dialogInfo: false,
  }),

  created() {
    this.$store.dispatch('locations/loadLocations', {
      country: this.form.country,
      adminarealevel: 1,
    });
  },

  computed: {
    adminAreasLevel1() {
      return [{
        name: 'Seleccionar provincia',
        id_area: '',
      }].concat(this.$store.getters['locations/getAdminAreasLevel1']);
    },
    localities() {
      return [{
        name: 'Seleccionar municipio',
        id_area: '',
      }].concat(this.$store.getters['locations/getAdminAreasLevel0']);
    },
    hasLocalities() {
      // Mayor que 1 por que la primera es la opción 'Seleccionar...'
      return this.localities.length > 1;
    },
    errorsForm() {
      return this.$store.getters['auth/getErrorsFormRegister'];
    },
    makingRequest() {
      return this.$store.getters['auth/getAuthStatusLoad'] === consts.REQUEST_HAS_STARTED;
    },

    registeredUser() {
      return this.$store.getters['auth/getRegisteredUser'];
    },

  },

  watch: {
    errorsForm(currentErrorsForm) {
      this.$refs['observer-form-register'].reset();

      console.log(currentErrorsForm);

      const errors = {};

      for (const field in currentErrorsForm) {
        errors[field] = [];
        for (const i_error in currentErrorsForm[field]) {
          errors[field].push(currentErrorsForm[field][i_error]);
        }
      }

      console.log(errors);

      this.$refs['observer-form-register'].setErrors(errors);
    },
  },

  methods: {
    /**
     * Método para enviar un nuevo registro al servidor, ejecutará diferentes acciones
     *  - Validación del formulario
     *  - Ejecución recaptcha
     */
    async createNewUser() {
      this.$store.dispatch('auth/startProcessRegister');
      this.$refs['observer-form-register'].reset();

      const isValid = await this.$refs['observer-form-register'].validate();

      if (!isValid) {
        this.$store.dispatch('auth/stopProcessRegister');
        return;
      }

      this.submit();
    },

    /**
     * Forma el objecto con los datos necesarios para crear un nuevo registro y envíar
     * estos al servidor.
     */
    submit() {
      const dataNewRegister = { // Establecemos los datos obligatorios.
        name: this.form.name,
        email: this.form.email,
        surname: this.form.surname,
        gender: this.form.gender,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
        license_number: this.form.license_number,
        tlf: this.form.tlf,
        // token recapcha
        recaptcha_token: this.form.recaptcha_token,
        record_book_diligence: this.form.record_book_diligence,
      };

      if (_.trim(this.form.second_surname) != '') {
        dataNewRegister.second_surname = this.form.second_surname;
      }

      const workArea = this.generateDataWorkArea();
      if (workArea !== null) {
        dataNewRegister.work_area = workArea;
      }

      const dataNewRegisterFormData = new FormData();

      this.buildFormData(dataNewRegisterFormData, dataNewRegister);

      this.$store.dispatch('auth/register', dataNewRegisterFormData);
    },

    // Métodos recaptcha, estas funciones irán en un mixin

    /**
     * El api de grecapcha nos devuelve una respuesta valida,
     * procedemos a validar y enviar los datos al servidor.
     * @param {string} recaptchaToken
     */
    onCaptchaVerified(recaptchaToken) {
      this.form.recaptcha_token = recaptchaToken;
    },

    /**
     * Ha expirado una respuesta validada, reseteamos grecapcha
     */
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
      this.form.recaptcha_token = null;
    },
    // Fin métodos grecaptcha

    /**
     * Crea un objeto con la información de la zona de trabajo elegido, si no ha seleccionado devolverá null.
     * Esta función la ponemos como método y no como propiedad computada, ya que será utilizada solo cuando enviamos el formulario.
     *
     * @returns mixed object|null
     */
    generateDataWorkArea() {
      if (this.form.adminAreaLevel1 === '') {
        return null;
      }

      const level = this.form.locality === '' ? 1 : 0;
      const id = this.form.locality === '' ? this.form.adminAreaLevel1 : this.form.locality;

      return {
        type: 'region',
        country: this.form.country,
        admin_area: {
          level,
          id,
        },
      };
    },

    /**
     * Muestra un popup con información sobre un tema.
     */
    showDialog(dialog) {
      this.dialog = this.dialogs[dialog];
      this.dialogInfo = true;
    },

    /**
     * Ejecuta una petición al servidor para obtener las localidades de una región elegida,
     * en caso de seleccionar ninguna, se limpiará el store con la localidades que pudiera tener guardadas.
     *
     * @param {mixed} idAdminAreasLevel1
     */
    getLocalities(idAdminAreasLevel1) {
      if (_.isInteger(idAdminAreasLevel1)) {
        this.$store.dispatch('locations/loadLocations', {
          country: 'ES',
          adminarealevel: 1,
          id: idAdminAreasLevel1,
        });
      } else {
        this.$store.dispatch('locations/clearLocations');
      }
    },

    /**
     * Poner en un helper este método, convierte un object anidado en un formdata
     * @param {*} formData
     * @param {*} data
     * @param {*} parentKey
     */
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
      }
    },

  },

  components: {
    VueRecaptcha,
  },

};
