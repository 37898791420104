/*
|-------------------------------------------------------------------------------
| API modules/constans.js
|-------------------------------------------------------------------------------
| The Vuex constants.
*/

export const REQUEST_WITHOUT_STARTING = 0;
export const REQUEST_HAS_STARTED = 1;
export const REQUEST_COMPLETE_SUCCESSFULLY = 2;
export const REQUEST_COMPLETE_UNSUCCESSFULLY = 3;
