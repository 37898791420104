/*
|-------------------------------------------------------------------------------
| modules/users/users/actions.js
|-------------------------------------------------------------------------------
| The actions Vuex for search module
*/

import * as consts from '../../../constants.js';
import SearchUsersAPI from '../../../api/search/users.js';

export default {

  /**
   * Obtiene un listado de usuarios
   *
   * @param      {int}    id
   */
  async filter({ commit }, filter) {
    commit('setSearchUsersStatusLoad', consts.REQUEST_HAS_STARTED);
    try {
      const response = await SearchUsersAPI.search(filter);
      console.log(response);
      if (!_.has(response, 'data.data')) {
        console.log('No se ha localidado ningún usuario');
        commit('setSearchUsersStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
        return;
      }

      commit('setUsers', response.data);
      commit('setSearchUsersStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
    } catch (e) {
      commit('setSearchUsersStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
    }
  },

};
