
/**
 * Número de niveles registrados de áreas administrativas
 *
 * @type       {number}
 */
const numberLevels = 3;

/**
 * Indica si está habilitada la selección de paises.
 *
 * Cuando se tenga que incluir selección de país, y se seleccione uno,
 * cada país tiene que tener guardado en su configuración el área administrativa
 * superior, ahora definido en el data del componente upperAdminAreaCountry.
 *
 * @type       {boolean}
 */
const enabledSelectCountry = false;

/**
 * Algunas propiedades computadas requieren de recorrer los diferentes niveles
 * de localización.
 * Es importante que estén en orden.
 *
 * @type       {object}
 */
const adminAreaLevels = [
  { name: 'adminAreaLevel0', level: 0 },
  { name: 'adminAreaLevel1', level: 1 },
  { name: 'adminAreaLevel2', level: 2 },
  { name: 'adminAreaLevel3', level: 3 },
  { name: 'country', level: 'country' },
];

/**
 * Valor inicial del formulario.
 *
 * @type       {object}
 */
const initForm = {
  country: 'ES',
  adminAreaLevel3: null,
  adminAreaLevel2: null,
  adminAreaLevel1: null,
  adminAreaLevel0: null,
};

export default {

  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    // Especifica solo en el label una localización cuando renderizamos el componente
    fullNameLocationDefault: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    upperAdminAreaCountry: 2,
    changed: false,
    form: { ...initForm },
  }),

  created() {
    this.$store.dispatch('locations/clearLocations', 3);

    /**
     * Según donde se inserte este componente, podemos desear que empiece la selección
     * desde país o un área administrativa inferior determinada, por lo que tendríamos
     * que proporcionar slots indicándolo.
     */
    this.$store.dispatch('locations/loadLocations', {
      country: this.form.country,
      adminarealevel: 2,
    });
  },

  computed: {
    adminAreasLevel() {
      return {
        2: [{
          name: 'Seleccionar comunidad',
          id_area: null,
        }].concat(this.$store.getters['locations/getAdminAreasLevel2']),
        1: [{
          name: 'Seleccionar provincia',
          id_area: null,
        }].concat(this.$store.getters['locations/getAdminAreasLevel1']),
        0: [{
          name: 'Seleccionar municipio',
          id_area: null,
        }].concat(this.$store.getters['locations/getAdminAreasLevel0']),
      };
    },

    /**
     * Comprueba por cada área si tiene establecidas localizaciones.
     *
     * @return     {Object}
     */
    hasLocations() {
      // Mayor que 1 por que la primera es la opción 'Seleccionar...'
      return {
        1: this.adminAreasLevel[1].length > 1,
        0: this.adminAreasLevel[0].length > 1,
      };
    },

    /**
     * Según el actual nivel de área seleccionado, se mostrará el nivel
     * de área inferior.
     *
     * @return     {Object}
     */
    enableSelectLocation() {
      return {
        2: !this.hasLocations[1],
        1: this.hasLocations[1] && !this.hasLocations[0],
        0: this.hasLocations[0],
      };
    },

    /**
     * Indica con valor numérico el nivel de el área administrativa seleccionada, si es un
     * país devolverá el valor 'country', si no hay nada seleccionado devolverá null.
     *
     * @return     {mixed}
     */
    adminAreaLevelSelected() {
      let adminAreaLevelSelected = null;

      adminAreaLevels.forEach(function (adminArealevelData) {
        const formAdminArealevel = this.form[adminArealevelData.name];
        if (this.isSettedIdArea(formAdminArealevel) && adminAreaLevelSelected === null) {
          adminAreaLevelSelected = adminArealevelData.level;
        }
      }, this);

      return adminAreaLevelSelected;
    },

    /**
     * Obtiene el nombre completo con sus áreas administravias superiores.
     *
     * @return     {string}
     */
    fullNameLocation() {
      let fullNameLocation = '';

      adminAreaLevels.forEach(function (adminArealevelData) {
        /* if (adminArealevelData.level == 'country') {
            return;
          } */
        const formAdminArealevel = this.form[adminArealevelData.name];
        if (this.isSettedIdArea(formAdminArealevel)) {
          fullNameLocation += `${formAdminArealevel.name}, `;
        }
      }, this);

      return fullNameLocation.replace(/,\s$/g, '');
    },

    labelLocation() {
      return this.fullNameLocationDefault && !this.changed
        ? this.fullNameLocationDefault
        : this.fullNameLocation;
    },

    requiredInfo() {
      return this.required ? '*' : '';
    }

  },

  /**
   * No encontramos la manera de que cuando cambiamos de valor algún modelo, sea
   * desde el select quien ejecute el handleSelectedLocation.
   */
  watch: {
    'form.adminAreaLevel3': function (val, oldVal) {
      this.handleSelectedLocation(3);
    },
    'form.adminAreaLevel2': function (val, oldVal) {
      this.handleSelectedLocation(2);
    },
    'form.adminAreaLevel1': function (val, oldVal) {
      this.handleSelectedLocation(1);
    },
    'form.adminAreaLevel0': function (val, oldVal) {
      this.handleSelectedLocation(0);
    },
  },

  	methods: {

    /**
     * Manejador cuando un selector de localidad o área ha cambiado de estado.
     *
     * @param      {mixed} adminAreaLevel   Numérico con el nivel de el área administrativa, un string con
     *                                      el valor 'country' si es un país, o null si no se ha seleccionado
     *                                      nada.
     */
    handleSelectedLocation(adminAreaLevel) {
      this.selectedLocation(adminAreaLevel);

      if (adminAreaLevel !== 0) { // Si es localidad ya no hay nada que obtener.
        this.getLocations(adminAreaLevel);
      }
    },

    /**
     * Ejecuta una petición al servidor para obtener las localidades de una región elegida,
     * en caso de seleccionar ninguna, se limpiará el store con la localidades que pudiera tener guardadas.
     *
     * @param {mixed} adminAreaLevel
     */
    getLocations(adminAreaLevel) {
      if (!this.isSettedIdArea(this.form[`adminAreaLevel${String(adminAreaLevel)}`])) {
        return;
      }

      this.$store.dispatch('locations/loadLocations', {
        country: this.form.country,
        adminarealevel: adminAreaLevel,
        id: this.form[`adminAreaLevel${String(adminAreaLevel)}`].id_area,
      });
    },

    /**
     * Indicamos visualizar un área administrativa. Para lo cual
     * borrará los items de las áreas administrativas inferiores.
     *
     * @param      {integer}  adminAreaLevel
     */
    viewSelectLocation(adminAreaLevel) {
      // Para visualizar un selector de areas, nos basamos en si su componente
      // hijo tiene caragados elementos, por lo tanto eliminamos los items
      // de el area inferior.

      // Establecemos la opción de "seleccionar (comunidad, provincia)"
      this.form[`adminAreaLevel${String(adminAreaLevel)}`] = this.adminAreasLevel[adminAreaLevel][0];

      const clearAdminAreaLevel = adminAreaLevel - 1;
      this.$store.dispatch('locations/clearLocations', clearAdminAreaLevel);
      this.form[`adminAreaLevel${String(clearAdminAreaLevel)}`] = null;
    },

    /**
     * Se ha (des)seleccionado una localización.
     * Generamos un emit con los datos de la actual localización seleccionada.
     *
     * @param      {mixed} adminAreaLevel   Numérico con el nivel de el área administrativa, un string con
     *                                      el valor 'country' si es un país, o null si no se ha seleccionado
     *                                      nada..
     */
    selectedLocation(adminAreaLevel) {
      this.changed = true;
      const { dataEmit } = this;

      this.$emit('selectedLocation', {
        newLocation: {
          ...this.form,
          ...{
            adminAreaLevel: this.adminAreaLevelSelected,
            adminAreaId: this.adminAreaLevelSelected != null ? this.form[`adminAreaLevel${String(this.adminAreaLevelSelected)}`].id_area : null,
            fullNameLocation: this.fullNameLocation,
          },
        },
        currentAdminAreaLevel: this.adminAreaLevelSelected,

      });
    },

    /**
     * Comprueba si un área administrativa tiene seteado un valor de localización
     *
     * @param      {object}  formAdminArealevel  The form admin arealevel
     * @return     {boolean}  { description_of_the_return_value }
     */
    isSettedIdArea(formAdminArealevel) {
      return _.has(formAdminArealevel, 'id_area') && formAdminArealevel.id_area != null;
    },

    // Establece una localización por defecto
    setLocation(location) {
      this.form = { ...location };
    },

    /**
     * Deseleccionamos la localacización elegida.
     */
    resetWorkArea() {
      console.log('Reseteamos');
      console.log(initForm);
      this.form = { ...initForm };
      this.$store.dispatch('locations/clearLocations', numberLevels - 2);
    },

  },

};
