import AddContactMeanBtn from './AddContactMeanBtn';


const MAX_CONTACT_MEANS = 4;

const initValueDriverContactType = {
  id_driver: null,
  name: 'Medio de contacto',
};

const initValueTypeContact = {
  type_contact: null,
  name: 'Tipo de contacto',
};

/**
 * Valor inicial del formulario.
 *
 * @type       {object}
 */
const initForm = {
  driver_contact_type: initValueDriverContactType,
  type_contact: initValueTypeContact,
  value_contact: '',
};

export default {

  name: 'contact-mean-select-component',

  data: () => ({

    form: { ...initForm },

    driversContactType: [
      {
        id_driver: null,
        name: 'Medio de contacto',
      },
      {
        id_driver: 0,
        name: 'Email',
        name_driver: 'email',
      },
      {
        id_driver: 1,
        name: 'Teléfono',
        name_driver: 'tlf',
      },
    ],

    typesContact: [
      {
        type_contact: null,
        name: 'Tipo de contacto',
      },
      {
        type_contact: 2,
        name: 'Profesional',
      },
      {
        type_contact: 1,
        name: 'Personal',
      },
    ],

  }),

  created() {
    this.checkReachedMaximumContactMean();
  },

  computed: {

    enableSelectDriverContactType() {
      return this.form.driver_contact_type.id_driver == null;
    },
    enableSelectTypeContact() {
      return this.form.driver_contact_type.id_driver != null
               && this.form.type_contact.type_contact == null;
    },

    // Indica si se ha seleccionado driver y typo de contacto.
    selectedDriverType() {
      return this.form.type_contact.type_contact != null;
    },

    showSetValueContact() {
      return {
        email: this.selectedDriverType && this.form.driver_contact_type.id_driver === 0,
        tlf: this.selectedDriverType && this.form.driver_contact_type.id_driver === 1,
      };
    },

    /**
       * Indica las opciones que se han ido eleigiendo en una cadena separada por comas
       * Email, personal
       * ....
       *
       * @return     {string}  { description_of_the_return_value }
       */
    optionsSelected() {
      let optionsSelected = '';

      if (this.form.driver_contact_type.id_driver != null) {
        optionsSelected += this.form.driver_contact_type.name;
      }

      if (this.form.type_contact.type_contact != null) {
        optionsSelected += `, ${this.form.type_contact.name}`;
      }

      return optionsSelected;
    },

    // Número de contactos
    contactMeanLength() {
      const contactMeans = this.$store.getters['user/getUser'].contact_means;

      return {
        emails: contactMeans.emails.length,
        tlfs: contactMeans.tlfs.length,
      };
    },

    // Indica si se ha alcanzado el máximo de contactos permitidos
    reachedMaximumContactMean() {
      return {
        email: MAX_CONTACT_MEANS <= this.contactMeanLength.emails,
        tlf: MAX_CONTACT_MEANS <= this.contactMeanLength.tlfs,
      };
    },

  },

  watch: {

    // Después de establecer un valor y no aceptar, podemos retroceder
    // cambiar el driver y tener el valor de tlf en un email por ejemplo.
    'form.type_contact': function (newValue, oldValue) {
      this.form.value_contact = '';
    },

    reachedMaximumContactMean() {
      this.checkReachedMaximumContactMean();
    },

  },

  methods: {

    viewSelectDriverContactType() {
      this.form.driver_contact_type = initValueDriverContactType;
    },

    viewSelectTypeContact() {
      this.form.type_contact = initValueTypeContact;
    },

    /**
       * Emite un evento mandando un contacto seleccionado.
       */
    addContact() {
      this.$emit('addContact', { ...this.form });
    },

    /**
       * Reinicia el formulario a sus valores iniciales
       */
    resetContactSelect() {
      this.form = { ...initForm };
    },

    // Si se ha alcanzado el máximo de contactos permitidos, deshabilitamos opción
    checkReachedMaximumContactMean() {
      const reachedMaximumContactMean = { ...this.reachedMaximumContactMean };
      for (const nameDriver in reachedMaximumContactMean) {
        const status = reachedMaximumContactMean[nameDriver];
        this.driversContactType.find(d => d.name_driver == nameDriver).disabled = status;
      }
    },

  	},

  components: {
    AddContactMeanBtn,
  },

};
