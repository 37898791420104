/*
|-------------------------------------------------------------------------------
| modules/collaborations/request/getters.js
|-------------------------------------------------------------------------------
| The getters Vuex for users module
*/

export default {
  getRequestCollaborationStatusLoad(state) {
    return state.requestCollaborationStatusLoad;
  },


  getRequestCollaboration(state) {
    return state.requestCollaboration;
  },

  // Obtiene array con las peticiones de colaboración
  getCollaborationRequests(state) {
    return state.collaborationRequests ? state.collaborationRequests.data : [];
  },

  getErrorsFormrequestCollaboration(state) {
    return state.errorsFormrequestCollaboration;
  },

  // Obtiene metainformación de la busqueda
  getMetaCollaborationRequests(state) {
    return state.collaborationRequests ? state.collaborationRequests.meta : null;
  },

  getCollaborationRequestsStatusLoad(state) {
    return state.collaborationRequestsStatusLoad;
  },
};
