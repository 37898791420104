import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTimes,
  faPlus,
  faPen,
  faCheck,
  faCircleNotch,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

/* import {

} from '@fortawesome/free-regular-svg-icons' */

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faTimes,
  faPlus,
  faPen,
  faCheck,
  faCircleNotch,
  faExclamationCircle,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);
