/*
|-------------------------------------------------------------------------------
| modules/user/getters.js
|-------------------------------------------------------------------------------
| The getters Vuex for users module
*/

import * as consts from '../../constants.js';

export default {
  getUserStatusLoad(state) {
    return state.userStatusLoad;
  },

  /**
	 * Obtiene el estado en el que se encuentra el proceso de  guardado
	 * en la parte backend de cada parte de el perfil de usuario.
	 */
  getDataManagmentStatus(state) {
    return state.dataManagmentStatus;
  },
  getLoadedProfile(state) {
    return state.userStatusLoad === consts.REQUEST_COMPLETE_SUCCESSFULLY;
  },
  getUser(state) {
    return state.user;
  },
  isAuthenticatedUser(state, getters, modules, rootGetters) {
    const profile = rootGetters['auth/getProfile'];
    return _.has(state.user, 'id') && _.has(profile, 'id') && state.user.id === profile.id;
  },

  getChangePasswordStatus(state) {
    return state.changePasswordStatus;
  },

  getHideProfileTimeStatus(state) {
    return state.hideProfileTimeStatus;
  },

  getShowProfileStatus(state) {
    return state.showProfileStatus;
  },

  getErrorsChangePassword(state) {
    return state.errorsChangePassword;
  },

};
