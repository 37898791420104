/*
|-------------------------------------------------------------------------------
| modules/sercvies/actions.js
|-------------------------------------------------------------------------------
| The actions Vuex for work equipment module
*/

import * as consts from '../../constants.js';
import WorkEquipmentAPI from '../../api/work_equipment.js';

export default {
  /**
     * Obtiene las herramienta de trabajo con las que un usuario puede trabajar
     *
     * @param      {int}    id
     */
  async getWorkEquipment({ dispatch, commit, getters }) {
    commit('setWorkEquipmentStatusLoad', consts.REQUEST_HAS_STARTED);

    let response;

    try {
      response = await WorkEquipmentAPI.getWorkEquipment();
    } catch (error) {
      commit('setWorkEquipmentStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      return;
    }

    commit('setworkEquipment', response.data);
    commit('setWorkEquipmentStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
  },

};
