/*
|-------------------------------------------------------------------------------
| modules/ages/actions.js
|-------------------------------------------------------------------------------
| The actions Vuex for ages module
*/

import * as consts from '@/constants';
import AgesAPI from '@/api/ages';

export default {
  /**
   * Obtiene los rangos de edad
   *
   * @param      {int}    id
   */
  async getAgeRanges({ commit }) {
    commit('setAgeRangesStatusLoad', consts.REQUEST_HAS_STARTED);

    let response;

    try {
      response = await AgesAPI.getAgeRanges();
    } catch (error) {
      commit('setAgeRangesStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      return;
    }

    commit('setAgeRanges', response.data);
    commit('setAgeRangesStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
  },

};
