
export default {

  name: 'detail-collaboration-request',

  props: {
    icon: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
  },

};
