import CookieConsent from 'vue-cookieconsent-component';
import {
  mdiHandshake,
  mdiAccountCircle,
  mdiAccountSearch,
  mdiLogout,
  mdiAccountBoxOutline,
} from '@mdi/js';
import Avatar from 'vue-avatar';
import NotificationsComponent from '../NotificationsComponent/NotificationsComponent';
import EventBus from '../EventBusComponent';

export default {
  name: 'page-app',
  data: () => ({
    dialog: false,
    drawer: false,
    drawer_filter: true,
    items_main: [],
    items_submenu: [
      {
        icon: mdiAccountBoxOutline,
        text: 'Cuenta',
        location: { name: 'Account' },
        show: true,
      },
      {
        icon: mdiLogout,
        text: 'Cerrar sesión',
        action: 'logoutApp',
        show: true,
      },
    ],
    items_bar: {
      right: [],
    },

    icons: {
      mdiLogout,
      mdiAccountBoxOutline,
    },


  }),
  props: {
    source: String,
  },

  computed: {
    isAuthenticate() {
      return this.$store.getters['auth/getToken'] !== null;
    },

    profile() {
      return this.$store.getters['auth/getProfile'];
    },

    isLogoutApp() {
      return this.profile === null;
    },

    showLoginNavbar() {
      return this.$route.name !== 'AuthLogin';
    },

    fullName() {
      return `${this.profile.name}  ${this.profile.surname}`;
    },

    itemsMainShow() {
      const itemsMain = [
        {
          icon: mdiAccountSearch,
          text: 'Buscar detectives',
          location: 'Users',
          show: true,
        },
        {
          icon: mdiHandshake,
          text: 'Peticiones de colaboracion',
          location: 'Collaborations',
          show: true,
        },
        {
          icon: mdiAccountCircle,
          text: 'Perfil',
          location: 'OwnProfile',
          show: this.$vuetify.breakpoint.smAndDown,
        },
        /* { icon: 'settings', text: 'Configuración', link: '/settings', },
        { icon: 'help', text: 'Ayuda', link: '/help', }, */
      ];
      return itemsMain.filter((item) => item.show);
    },

  },

  watch: {
    isLogoutApp(status) {
      if (status) {
        this.$router.push('/login');
        this.comingOut = false;
      }
    },
  },

  methods: {
    logoutApp() {
      this.$store.dispatch('auth/logoutApp');
    },

    //
    emitMethodBtnBar(event) {
      EventBus.$emit(event);
    },

    handle_function_call(fn) {
      this[fn]();
    },

  },

  components: {
    CookieConsent,
    NotificationsComponent,
    Avatar,
  },

};
