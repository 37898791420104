/*
|-------------------------------------------------------------------------------
| modules/contact_us/actions.js
|-------------------------------------------------------------------------------
| The actions Vuex for contact us module
*/

import * as consts from '@/constants';
import ContactUsAPI from '@/api/contact_us';

export default {
  /**
   * Envía un mensaje por el formulario de contacto
   *
   * @param      {int}    id
   */
  async sendMessage({ commit }, contact) {
    try {
      const response = await ContactUsAPI.sendMessage(contact);
      if (response.status >= 200 && response.status < 300) {
        commit('setSendMessageStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
        return;
      }

      if (response.status === 422 && _.has(response, 'data.payload.msg')) {
        commit('setErrorsSendMessage', response.data.payload.msg);
      }

      commit('setSendMessageStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);

    } catch (error) {
      commit('setSendMessageStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
    }
  },
};
