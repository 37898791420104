/*
|-------------------------------------------------------------------------------
| modules/contact_us/getters.js
|-------------------------------------------------------------------------------
| The getters Vuex for contact us module
*/

export default {

  getSendMessageStatusLoad(state) {
    return state.sendMessageStatusLoad;
  },

  getErrorsSendMessage(state) {
    return state.errorsSendMessage;
  },

};
