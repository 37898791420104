/*
|-------------------------------------------------------------------------------
| modules/associations/getters.js
|-------------------------------------------------------------------------------
| The Vuex data store for associations that may belong to the user
*/

export default {

  /**
	 * Obtiene el estado en el que se encuentra el proceso de obtención de las
	 * asociaciones.
	 */
  getDataAssociationsStatus(state) {
    return state.dataAssociationsStatus;
  },

  /**
	 * Obtiene las asociaciones a las que puede pertenecer un usuario.
	 *
	 * @param {object} state
	 */
  getAssociations(state) {
    return state.associations;
  },

};
