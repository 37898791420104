import {
  mdiFileDocumentEditOutline,
  mdiEye,
  mdiEyeOffOutline,
  mdiPlus,
  mdiBriefcaseRemoveOutline,
} from '@mdi/js';
import * as consts from '@/constants.js';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {

  name: 'page-collaborations',

  data: () => ({

    page: 1,
    fab: false,
    unwatchFilter: null,

    ownerRequest: null,

    icons: {
      mdiFileDocumentEditOutline,
      mdiEyeOffOutline,
      mdiEye,
      mdiPlus,
      mdiBriefcaseRemoveOutline,
    },
  }),

  created() {
    moment.locale('es');
    this.page = 1;
    this.ownerRequest = this.$route.meta.owner;
    this.$store.dispatch('requestCollaboration/search', {});
  },

  computed: {

    ...mapGetters({
      collaborationRequests: 'requestCollaboration/getCollaborationRequests',
      metaCR: 'requestCollaboration/getMetaCollaborationRequests',
      crStatusLoad: 'requestCollaboration/getCollaborationRequestsStatusLoad',
    }),

    pagination() {
      this.page = this.metaCR ? this.metaCR.current_page : 1;
    },

    loading() {
      return this.crStatusLoad === consts.REQUEST_HAS_STARTED;
    },

    filters() {
      return {
        page: this.page,
        owner: this.ownerRequest,
      };
    },

    notFoundCollaborationRequest() {
      return Array.isArray(this.collaborationRequests) && this.collaborationRequests.length === 0;
    },

  },

  watch: {
    page: function(oldVal, val){
      this.search()
    },
    $route(to/* , from */) {
      this.ownerRequest = to.meta.owner;
    },
  },

  methods: {

    async search() {
      this.$store.dispatch('requestCollaboration/search', this.filters);
    },

    truncateDescription(txt) {
      return _.truncate(txt, {
        length: this.$vuetify.breakpoint.mdAndUp ? 400 : 300,
      });
    },

    dateLocale(date) {
      return moment(date).format('LL');
    },

    /**
     * @param {bool} me
     */
    viewMyRequest(me) {
      this.ownerRequest = me;
      this.page = 1;
      this.search();
    },

  },

};
