/*
|-------------------------------------------------------------------------------
| modules/ages/mutations.js
|-------------------------------------------------------------------------------
| The mutations Vuex for ages module
*/

export default {

  /**
   * Indica el estado en el que se encuentra la obtención de rangos de edad
   *
   * @param {object} state
   * @param {numeric} status
   */
  setAgeRangesStatusLoad(state, status) {
    state.dataAgeRangesStatus = status;
  },

  /**
   * Establece los ranos de edad
   *
   * @param {object} state
   * @param {array} ageRanges
   */
  setAgeRanges(state, ageRanges) {
    state.age_ranges = ageRanges;
  },

};
