/*
|-------------------------------------------------------------------------------
| API modules/locations.js
|-------------------------------------------------------------------------------
| The Vuex data store for locations.
*/

import * as consts from '../constants.js';
import LocationsAPI	from '../api/locations.js';

export default {
  namespaced: true,
  state: {
    locationsStatusLoad: consts.REQUEST_WITHOUT_STARTING,
    localities: [],
    adminAreasLevel1: [],
    adminAreasLevel2: [],
    adminAreasLevel3: [],
    countries: [],
  },

  actions: {
    /**
		 * Carga las localizaciones para un país y de nivel determinado.
		 * Cargar por ejemplo todas las comunidades o provincias de españa.
		 * Si la propiedad id es proporcionada se cargan las localizaciones para
		 * un pais y nivel determinado, todas las provincias de una comunidad.
		 *
		 * @param      object    query      {
	 	 * 										'country' : 'XX',
	 	 * 										'adminarealevel' (int){1,3},
		 * 										'id': (int) optional
		 * 									}
		 */
    loadLocations({ commit }, query) {
      commit('setLocationsStatusLoad', consts.REQUEST_HAS_STARTED);
      let areaAdminToSet = String(_.has(query, 'id')
        ? query.adminarealevel - 1
        : query.adminarealevel);
      areaAdminToSet = `setAdminAreasLevel${areaAdminToSet}`;
      let locations = [];
      LocationsAPI.loadLocations(query)
        .then((response) => {
          if (response.data.response === true) {
            commit('setLocationsStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
            locations = response.data.locations;
          }
          commit(areaAdminToSet, locations);
        })
        .catch((e) => {
          commit(areaAdminToSet, locations);
        });
    },

    clearLocations({ commit }, adminAreaLevel) {
      for (let level = adminAreaLevel; level >= 0; level--) {
        commit(`setAdminAreasLevel${String(level)}`, []);
      }
    },
  },

  mutations: {
    setLocationsStatusLoad(state, status) {
      state.locationsStatusLoad = status;
    },
    setAdminAreasLevel0(state, localities) {
      state.localities = localities;
    },
    setAdminAreasLevel1(state, adminAreasLevel1) {
      state.adminAreasLevel1 = adminAreasLevel1;
    },
    setAdminAreasLevel2(state, adminAreasLevel2) {
      state.adminAreasLevel2 = adminAreasLevel2;
    },
    setAdminAreasLevel3(state, adminAreasLevel3) {
      state.adminAreasLevel3 = adminAreasLevel3;
    },
  },

  getters: {
    getLocationsStatusLoad(state) {
      return state.locationsStatusLoad;
    },
    getAdminAreasLevel0(state) {
      return state.localities;
    },
    getAdminAreasLevel1(state) {
      return state.adminAreasLevel1;
    },
    getAdminAreasLevel2(state) {
      return state.adminAreasLevel2;
    },
    getAdminAreasLevel3(state) {
      return state.adminAreasLevel3;
    },
  },

};
