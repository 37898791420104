/*
|-------------------------------------------------------------------------------
| API modules/auth.js
|-------------------------------------------------------------------------------
| The Vuex data store for authentication.
*/

import * as consts from '../constants.js';
import AuthAPI	from '../api/auth.js';

export default {
  namespaced: true,
  state: {
    registeredUser: {
      status: false,
      msg: '',
    },
    authStatusLoad: consts.REQUEST_WITHOUT_STARTING,
    restorePasswordStatusLoad: consts.REQUEST_WITHOUT_STARTING,
    resetPasswordStatusLoad: consts.REQUEST_WITHOUT_STARTING,
    restoredPassword: {
      msg: '',
    },
    resetPassword: {
      msg: '',
    },
    logoutStatusLoad: consts.REQUEST_WITHOUT_STARTING,
    token: localStorage.getItem('token') || null,
    profile: JSON.parse(localStorage.getItem('profile') || null),
    errorsFormRegister: [],
    errorsFormLogin: [],
    errorsFormRestorePassword: [],
    errorsFormResetPassword: [],
    isVerificationAccount: false,
    verifiedAccount: null,
  },

  actions: {
    startProcessRegister({ commit }) {
      commit('setAuthStatusLoad', consts.REQUEST_HAS_STARTED);
    },
    stopProcessRegister({ commit }) {
      commit('setAuthStatusLoad', consts.REQUEST_WITHOUT_STARTING);
    },
    register({ commit }, data) {
      AuthAPI.register(data)
        .then((response) => {
          if (response.data.status === true) {
            commit('setAuthStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
            commit('setRegisteredUser', {
              status: true,
              msg: response.data.msg,
            });
          } else {
            commit('setAuthStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
            if (_.has(response, 'data.payload.msg')) {
              commit('setErrorsFormRegister', response.data.payload.msg);
            }
          }
        })
        .catch((response) => {
          commit('setAuthStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
        });
    },
    login({ commit, getters }, credentials) {
      commit('setAuthStatusLoad', consts.REQUEST_HAS_STARTED);
      const api = _.has(credentials, 'key_verification')
        ? AuthAPI.loginVerifyEmail(credentials)
        : AuthAPI.login(credentials);
      api.then((response) => {
        console.log(response.status);
        if (response.data.status === true) {
          if (getters.getVerificationAccount) {
            commit('setVerifiedAccount', response.data.payload);
          } else {
            commit('setToken', response.data.payload.token);
            commit('setProfile', response.data.payload.user);
          }

          commit('setAuthStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
        } else {
          commit('setAuthStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
          console.log('1');
          if (_.has(response, 'data.payload.msg')) {
            commit('setErrorsFormLogin', response.data.payload.msg);
          } else if (response.status === 429) {
            console.log('2');
            commit('setErrorsFormLogin', ['Demasiadas peticiones, espere unos mínutos para intentarlo de nuevo.']);
          }
        }
      })
        .catch(() => {
          commit('setAuthStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
        });
    },
    restorePassword({ commit }, email) {
      commit('setRestorePasswordStatusLoad', consts.REQUEST_HAS_STARTED);

      AuthAPI.restorePassword(email).then((response) => {
        if (response.data.status === true) {
          commit('setRestorePasswordStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
          commit('setRestoredPassword', response.data.payload);
        } else {
          commit('setRestorePasswordStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
          if (_.has(response, 'data.payload.msg')) {
            commit('setErrorsFormRestorePassword', response.data.payload.msg);
          }
        }
      })
        .catch(() => {
          commit('setErrorsFormRestorePassword', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
        });
    },
    resetPassword({ commit }, data) {
      commit('setResetPasswordStatusLoad', consts.REQUEST_HAS_STARTED);
      AuthAPI.resetPassword(data).then((response) => {
        if (response.data.status === true) {
          commit('setResetPasswordStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
          commit('setResetPassword', response.data.payload);
        } else {
          commit('setResetPasswordStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
          if (_.has(response, 'data.payload.msg')) {
            commit('setErrorsFormResetPassword', response.data.payload.msg);
          }
        }
      })
        .catch(() => {
          commit('setErrorsFormResetPassword', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
        });
    },
    setVerificationAccount({ commit }, isVerificationAccount) {
      commit('setVerificationAccount', isVerificationAccount);
    },
    logoutApp({ commit }) {
      commit('setLogoutStatusLoad', consts.REQUEST_HAS_STARTED);
      commit('setToken', null);
      commit('setProfile', null);
      commit('setLogoutStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
    },
  },

  mutations: {
    setToken(state, token) {
      state.token = token;
      token ? localStorage.setItem('token', token) : localStorage.removeItem('token');
    },
    setProfile(state, profile) {
      profile
        ? localStorage.setItem('profile', JSON.stringify(profile))
        : localStorage.removeItem('profile');
      state.profile = profile;
    },
    updateProfileLocalStorage(profile) {
      localStorage.setItem('profile', JSON.stringify(profile));
    },
    setAuthStatusLoad(state, status) {
      state.authStatusLoad = status;
    },
    setRestorePasswordStatusLoad(state, status) {
      state.restorePasswordStatusLoad = status;
    },
    setResetPasswordStatusLoad(state, status) {
      state.resetPasswordStatusLoad = status;
    },
    setRestoredPassword(state, restoredPassword) {
      state.restoredPassword = restoredPassword;
    },
    setResetPassword(state, resetPassword) {
      state.resetPassword = resetPassword;
    },
    setErrorsFormRegister(state, errors) {
      state.errorsFormRegister = errors;
    },
    setErrorsFormLogin(state, errors) {
      state.errorsFormLogin = errors;
    },
    setErrorsFormRestorePassword(state, errors) {
      state.errorsFormRestorePassword = errors;
    },
    setErrorsFormResetPassword(state, errors) {
      state.errorsFormResetPassword = errors;
    },
    setRegisteredUser(state, data) {
      state.registeredUser = data;
    },
    setVerificationAccount(state, isVerificationAccount) {
      state.isVerificationAccount = isVerificationAccount;
    },
    setLogoutStatusLoad(state, status) {
      state.logoutStatusLoad = status;
    },
    setVerifiedAccount(state, payload) {
      state.verifiedAccount = payload;
    },
    setHiddenUpProfile(state, hiddenUp) {
      state.profile.hidden_up = hiddenUp;
      localStorage.setItem('profile', JSON.stringify(state.profile));
    },
  },

  getters: {
    getToken(state) {
      return state.token;
    },
    getProfile(state) {
      return state.profile;
    },
    getAuthStatusLoad(state) {
      return state.authStatusLoad;
    },
    getRestorePasswordStatusLoad(state) {
      return state.restorePasswordStatusLoad;
    },
    getResetPasswordStatusLoad(state) {
      return state.resetPasswordStatusLoad;
    },
    getRestoredPassword(state) {
      return state.restoredPassword;
    },
    getResetPassword(state) {
      return state.resetPassword;
    },
    getErrorsFormRegister(state) {
      return state.errorsFormRegister;
    },
    getErrorsFormLogin(state) {
      return state.errorsFormLogin;
    },
    getErrorsFormRestorePassword(state) {
      return state.errorsFormRestorePassword;
    },
    getErrorsFormResetPassword(state) {
      return state.errorsFormResetPassword;
    },
    getRegisteredUser(state) {
      return state.registeredUser;
    },
    getVerificationAccount(state) {
      return state.isVerificationAccount;
    },
    getLogoutStatusLoad(state) {
      return state.logoutStatusLoad;
    },
    getVerifiedAccount(state) {
      return state.verifiedAccount;
    },
  },

};
