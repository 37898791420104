import * as consts from '../../constants.js';

export default {

  props: {
    status: {
      type: Number,
      default: consts.REQUEST_WITHOUT_STARTING,
    },
    editData: {
      type: Object,
    },
    btn: {
      type: Object,
      default() {
        return {
          accept: {
            disabled: false,
          },
        };
      },
    },
    changeData: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    action() {
      return {
        start: this.$props.status == consts.REQUEST_HAS_STARTED,
      };
    },
  },

  methods: {

    /**
     * Pulsado botón de edición.
     */
    editAction() {
      this.$emit('editAction');
    },

    /**
     * Cancelar formulario
     */
    cancelForm() {
      this.$emit('cancelForm');
    },

    /**
     * Aceptar formulario
     */
    acceptForm() {
      this.$emit('acceptForm');
    },

  },

};
