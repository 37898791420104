import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.grey.darken4,
        secondary: colors.grey.lighten1,
        accent: colors.shades.black,
        error: '#b71c1c',
        success: colors.green,
        navbar: colors.grey.darken4,
        footer: colors.grey.darken4,
      },
      dark: {
        primary: colors.grey.lighten3,
        secondary: colors.grey.lighten1,
        accent: colors.grey.lighten2,
        success: colors.green.darken1,
        error: colors.red,
        navbar: colors.black,
        footer: colors.black,
      },
    },
  },
});
