import VueRecaptcha from 'vue-recaptcha';
import { mapGetters } from 'vuex';
import * as consts from '@/constants';
import {
  mdiSendOutline,
  mdiCheckAll,
  mdiAlertCircleOutline,
} from '@mdi/js';


const MESSAGE_SENT = 1;

// Se ha producido un error desconocido en servidor
const UNKNOWN_ERROR = 2;

export default {
  data: () => ({
    icons: {
      mdiSendOutline,
      mdiCheckAll,
    },

    form: {
      name: '',
      surnames: '',
      email: '',
      subject: '',
      body: '',
      recaptcha_token: null,
      accept_terms: false,
    },

    messageSentResponse: false,

    response: {
      MESSAGE_SENT: {
        icon: mdiCheckAll,
        color: 'success',
        msg: 'Gracias por ponerte en contacto con Detecfinder, intentaremos contactarte lo antes posible.',
      },
      UNKNOWN_ERROR: {
        icon: mdiAlertCircleOutline,
        color: 'error',
        msg: 'Parece que tenemos problemas para tramitar el mensaje, inténtelo en otro momento o pongase en contacto mediante el correo info@detecfinder.com.',
      },
    },

  }),

  computed: {
    sending() {
      return this.sendMessageStatusLoad === consts.REQUEST_HAS_STARTED;
    },

    ...mapGetters({
      sendMessageStatusLoad: 'contactUs/getSendMessageStatusLoad',
      errorsForm: 'contactUs/getErrorsSendMessage',
    }),

    sentMessage() {
      return this.messageSentResponse === MESSAGE_SENT;
    },

    unknownErrorMessage() {
      return this.messageSentResponse === UNKNOWN_ERROR;
    },

  },

  watch: {
    sendMessageStatusLoad(status) {
      if (status === consts.REQUEST_COMPLETE_SUCCESSFULLY) {
        this.messageSentResponse = this.response.MESSAGE_SENT;
      } else if (status === consts.REQUEST_COMPLETE_UNSUCCESSFULLY && this.errorsForm === null) {
        this.messageSentResponse = this.response.UNKNOWN_ERROR;
      }
    },
    errorsForm(currentErrorsForm) {
      this.$refs['observer-form-contact-us'].reset();
      const errors = {};
      for (const field in currentErrorsForm) {
        errors[field] = [];
        for (const i_error in currentErrorsForm[field]) {
          errors[field].push(currentErrorsForm[field][i_error]);
        }
      }
      console.log(
        'Seteamos errores',
        errors,
      );
      this.$refs['observer-form-contact-us'].setErrors(errors);
    },
  },

  components: {
    VueRecaptcha,
  },

  methods: {
    /**
     * Método para enviar formulario
     *  - Validación del formulario
     *  - Ejecución recaptcha
     */
    async sendAction() {
      this.$store.commit(
        'contactUs/setSendMessageStatusLoad',
        consts.REQUEST_HAS_STARTED
      );
      this.$store.commit(
        'contactUs/setErrorsSendMessage',
        null
      );

      this.$refs['observer-form-contact-us'].reset();

      const isValid = await this.$refs['observer-form-contact-us'].validate();

      if (!isValid) {
        this.$store.commit(
          'contactUs/setSendMessageStatusLoad',
          consts.REQUEST_WITHOUT_STARTING
        );
        return;
      }

      this.$store.dispatch('contactUs/sendMessage', this.form);
    },

    // Métodos recaptcha, estas funciones irán en un mixin

    /**
     * El api de grecapcha nos devuelve una respuesta valida.
     * @param {string} recaptchaToken
     */
    onCaptchaVerified(recaptchaToken) {
      console.log(`Se ha verificado${recaptchaToken}`);

      this.form.recaptcha_token = recaptchaToken;
    },

    /**
     * Ha expirado una respuesta validada, reseteamos grecapcha
     */
    onCaptchaExpired() {
      this.$refs.recaptchaContactUs.reset();
      this.form.recaptcha_token = null;
    },
    // Fin métodos grecaptcha
  },
};
