/*
|-------------------------------------------------------------------------------
| modules/work_equipment/mutations.js
|-------------------------------------------------------------------------------
| The mutations Vuex for work equipment module
*/

export default {

  /**
     * Indica el estado en el que se encuentra la obtención de herramientas de trabajo disponibles al servidor
     *
     * @param {object} state
     * @param {numeric} status
     */
  setWorkEquipmentStatusLoad(state, status) {
    state.dataWorkEquipmentStatus = status;
  },

  /**
     * Establece las herramientas de trabajo que puede tener un usuario
     *
     * @param {object} state
     * @param {array} workEquipment
     */
  setworkEquipment(state, workEquipment) {
    state.workEquipment = workEquipment;
  },

};
