/*
|-------------------------------------------------------------------------------
| API api/ages.js
|-------------------------------------------------------------------------------
| The api for ages
*/

export default {

  /**
   * Obtiene los rangos de edad.
   *
   * @return     {Promise}
   */
  getAgeRanges() {
    return axios.get('/api/age-ranges');
  },

};
