/*
|-------------------------------------------------------------------------------
| API modules/languages/index.js
|-------------------------------------------------------------------------------
| The Vuex data store for languages that may belong to the user
*/

import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import state from './state';

export const languages = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
