/*
|-------------------------------------------------------------------------------
| modules/races/getters.js
|-------------------------------------------------------------------------------
| The Vuex data store for races that may belong to the user
*/

export default {

  /**
	 * Obtiene el estado en el que se encuentra el proceso de obtención de las razas.
	 */
  getDataRacesStatus(state) {
    return state.dataRacesStatus;
  },

  /**
	 * Obtiene las razas
	 *
	 * @param {object} state
	 */
  getRaces(state) {
    return state.races;
  },

};
