/*
|-------------------------------------------------------------------------------
| modules/users/filters/getters.js
|-------------------------------------------------------------------------------
| The getters Vuex for filters module
*/

export default {

  getFilters(state) {
    return state.filters;
  },

};
