/*
|-------------------------------------------------------------------------------
| modules/collaborations/collaboration/state.js
|-------------------------------------------------------------------------------
| The state Vuex for request collaborations module
*/

import * as consts from '@/constants.js';

export default {
  collaborationStatusLoad: consts.REQUEST_WITHOUT_STARTING,
  collaboration: null,
  disableCollaborationRequestStatusLoad: consts.REQUEST_WITHOUT_STARTING,
  deleteCollaborationRequestStatusLoad: consts.REQUEST_WITHOUT_STARTING,
};
