/*
|-------------------------------------------------------------------------------
| modules/languages/getters.js
|-------------------------------------------------------------------------------
| The Vuex data store for languages that may belong to the user
*/

export default {

  /**
	 * Obtiene el estado en el que se encuentra el proceso de obtención de los
	 * idiomas.
	 */
  getDataLanguagesStatus(state) {
    return state.dataLanguagesStatus;
  },

  /**
	 * Obtiene los idiomas que puede tener un usuario.
	 *
	 * @param {object} state
	 */
  getLanguages(state) {
    return state.languages;
  },

};
