<template>
  <v-btn
    fab
    :disabled="disabledAddContact"
    small
    bottom
    color="primary"
  >
    <v-icon
      size="28"
      @click="addContact()"
    >
      mdi-plus
    </v-icon>
  </v-btn>
</template>

<script>

export default {

  props: {
    disabledAddContact: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({

  }),

  methods: {

    addContact() {
      this.$emit('addContact');
    },

  },

};

</script>
