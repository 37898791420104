/*
|-------------------------------------------------------------------------------
| modules/collaborations/collaboration/getters.js
|-------------------------------------------------------------------------------
| The getters Vuex for users module
*/

export default {

  get(state) {
    return state.collaboration;
  },

  getStatusLoad(state) {
    return state.collaborationStatusLoad;
  },

  getDisableCollaborationRequestStatusLoad(state) {
    return state.disableCollaborationRequestStatusLoad;
  },

  getDeleteCollaborationRequestStatusLoad(state) {
    return state.deleteCollaborationRequestStatusLoad;
  },

};
