import * as consts from '@/constants.js';


export default {

  data: () => ({
    // Data form
    form: {
      password: '',
      password_confirmation: '',
    },
  }),

  computed: {
    makingRequest() {
      return this.$store.getters['auth/getResetPasswordStatusLoad'] === consts.REQUEST_HAS_STARTED;
    },
    isResetPassword() {
      return this.$store.getters['auth/getResetPasswordStatusLoad'] === consts.REQUEST_COMPLETE_SUCCESSFULLY;
    },
    resetedPassword() {
      return this.$store.getters['auth/getResetPassword'];
    },
    errorsForm() {
      return this.$store.getters['auth/getErrorsFormResetPassword'];
    },
  },

  watch: {
    errorsForm(currentErrorsForm) {
      this.$refs['observer-form-reset-password'].reset();
      const errors = {};

      for (const field in currentErrorsForm) {
        errors[field] = [];
        if (typeof currentErrorsForm[field] === 'string') {
          errors.general = currentErrorsForm[field];
        } else {
          for (const i_error in currentErrorsForm[field]) {
            errors[field].push(currentErrorsForm[field][i_error]);
          }
        }
      }

      this.$refs['observer-form-reset-password'].setErrors(errors);
    },
  },

  methods: {
    async resetPassword() {
      this.$refs['observer-form-reset-password'].reset();

      const isValid = await this.$refs['observer-form-reset-password'].validate();
      if (!isValid) {
        return;
      }

      const data = {
        token: this.$route.params.token,
        email: this.$route.query.email,
        password: this.form.password,
        password_confirmation: this.form.password_confirmation,
      };

      this.$store.dispatch('auth/resetPassword', data);
    },
  },
};
