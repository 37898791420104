/*
|-------------------------------------------------------------------------------
| modules/languages/actions.js
|-------------------------------------------------------------------------------
| The Vuex data store for languages that may belong to the user
*/

import * as consts from '../../constants.js';
import LanguagesAPI from '../../api/languages.js';

export default {
  /**
     * Obtiene los idiomas
     *
     * @param      {int}    id
     */
  async getLanguages({ commit }) {
    commit('setLanguagesStatusLoad', consts.REQUEST_HAS_STARTED);

    let response;

    try {
      response = await LanguagesAPI.getLanguages();
    } catch (error) {
      commit('setLanguagesStatusLoad', consts.REQUEST_COMPLETE_UNSUCCESSFULLY);
      return;
    }

    commit('setLanguages', response.data);
    commit('setLanguagesStatusLoad', consts.REQUEST_COMPLETE_SUCCESSFULLY);
  },

};
