/*
|-------------------------------------------------------------------------------
| modules/work_equipment/getters.js
|-------------------------------------------------------------------------------
| The getters Vuex for work equipment module
*/

export default {

  /**
	 * Obtiene el estado en el que se encuentra el proceso de obtención de los
	 * equipos de trabajo que tener prestar un usuario.
	 */
  getDataWorkEquipmentStatus(state) {
    return state.dataWorkEquipmentStatus;
  },

  /**
	 * Obtiene los equipos de trabajo que puede tener un usuario.
	 *
	 * @param {object} state
	 */
  getWorkEquipment(state) {
    return state.workEquipment;
  },

};
