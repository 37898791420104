/*
|-------------------------------------------------------------------------------
| API api/search.js
|-------------------------------------------------------------------------------
| The api for api search
*/

export default {

  /**
	 * Get list users.
	 *
	 * @param      {object}  filter  The filters
	 * @return     {Promise}
	 */
  search(filter) {

      console.log(
        'filter',
        filter,
      );

    return axios.get('/api/users/search/', {
      params: filter,
    });
  },

};
