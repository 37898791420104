/*
|-------------------------------------------------------------------------------
| API modules/users/filters/index.js
|-------------------------------------------------------------------------------
| The Vuex data store for filters
*/

import mutations from './mutations';
import getters from './getters';
import state from './state';

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
