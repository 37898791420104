/**
 * Stores any of configuration in this file.
 */

const constants = {
  BASE_URL: window.app_env.VUE_APP_BASE_URL,

  /**
	 * Google tiene una clave web y privada para entornos de prueba
	 * https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha-v2-what-should-i-do
	 */
  RECAPTCHA_V2_SITE_KEY: window.app_env.VUE_APP_RECAPTCHA_V2_SITE_KEY,
  RECAPTCHA_V3_SITE_KEY: window.app_env.VUE_APP_RECAPTCHA_V3_SITE_KEY,
};

export default constants;
