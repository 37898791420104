import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate';

import * as rules from 'vee-validate/dist/rules';
import es from 'vee-validate/dist/locale/es';

// loop over all rules
for (const rule in rules) {
  extend(rule, {
    ...rules[rule], // add the rule
    message: es.messages[rule], // add its message
  });
}

extend('license_number', {
  ...rules.between,
  message: 'El número de licencia no es correcto.',
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
