
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from 'vue';
import router from './routes.js';
import store from './store.js';
import AppComponent from './components/AppComponent/AppComponent.vue';
import vuetify from './plugins/vuetify';
import './registerServiceWorker';

import './plugins/vee_validate';
import './plugins/fortawesome';
import './plugins/get_constants';
import './plugins/vue_notifications';
import { i18n } from './plugins/vue_i18n.js';

window._ = require('lodash');

// eslint-disable-next-line no-undef
_.mixin({ pascalCase: _.flow(_.camelCase, _.upperFirst) });

try {
  window.Popper = require('popper.js').default;
  window.$ = window.jQuery = require('jquery');

  require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

const token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
  window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
  console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window.axios.defaults.validateStatus = function (status) {
  return status >= 200 && status < 500;
};

// Add a request interceptor
window.axios.interceptors.request.use((config) => {
  // Do something before request is sent
  const token = localStorage.getItem('token');

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}, error =>
  // Do something with request error
  Promise.reject(error));

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(AppComponent),
}).$mount('#app');
