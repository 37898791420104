export default {
  name: 'Footer',
  data: () => ({
    itemsFooter: [
      {
        label: 'Acerca de',
        link: 'AboutAs',
      },
      {
        label: 'Aviso Legal y Condiciones de uso',
        link: 'LegalTerms',
      },
      {
        label: 'Privacidad y Cookies',
        link: 'Privacy',
      },
      {
        label: 'Contacto',
        link: 'ContactUs',
      },
    ],
  }),

  computed: {
    profile() {
      return this.$store.getters['auth/getProfile'];
    },

    items() {

      let items = this.itemsFooter;

      console.log('hola');

      if (this.profile === null) {
        items = [{
          label: 'Inicio',
          link: 'Home',
        }].concat(this.itemsFooter);
      }

      return items;

    },

  },


};
