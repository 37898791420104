var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[_c('v-container',[_c('v-layout',{attrs:{"d-block":""}},[_c('v-flex',[_c('p',{staticClass:"subtitle-1 pb-0 mb-0"},[_vm._v(" "+_vm._s(_vm.optionsSelected)+" ")])]),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableSelectDriverContactType),expression:"enableSelectDriverContactType"}]},[_c('v-select',{attrs:{"data-vv-name":"driver_contact_type","data-vv-as":"medio de contacto","label":"Medio de contacto","items":_vm.driversContactType,"item-text":"name","return-object":""},model:{value:(_vm.form.driver_contact_type),callback:function ($$v) {_vm.$set(_vm.form, "driver_contact_type", $$v)},expression:"form.driver_contact_type"}})],1),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.enableSelectTypeContact),expression:"enableSelectTypeContact"}]},[_c('v-select',{attrs:{"data-vv-name":"type_contact","data-vv-as":"tipo de contacto","label":"Tipo de contacto","items":_vm.typesContact,"item-text":"name","return-object":""},model:{value:(_vm.form.type_contact),callback:function ($$v) {_vm.$set(_vm.form, "type_contact", $$v)},expression:"form.type_contact"}},[_c('v-tooltip',{attrs:{"slot":"prepend","bottom":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30"},on:{"click":function($event){return _vm.viewSelectDriverContactType()}}},on),[_vm._v(" mdi-chevron-left ")])]}}])},[_c('span',[_vm._v("Volver a seleccionar medio de contacto.")])])],1)],1),_c('v-flex',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedDriverType),expression:"selectedDriverType"}]},[_c('validation-provider',{ref:"email_value_contact",attrs:{"rules":"required|email","name":"email","debounce":500},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSetValueContact.email),expression:"showSetValueContact.email"}],attrs:{"prepend-inner-icon":"mdi-email","error-messages":errors[0]},model:{value:(_vm.form.value_contact),callback:function ($$v) {_vm.$set(_vm.form, "value_contact", $$v)},expression:"form.value_contact"}},[_c('v-tooltip',{attrs:{"slot":"prepend","bottom":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30"},on:{"click":function($event){return _vm.viewSelectTypeContact()}}},on),[_vm._v(" mdi-chevron-left ")])]}}],null,true)},[_c('span',[_vm._v("Volver a seleccionar tipo de contacto.")])]),_c('template',{slot:"append-outer"},[_c('AddContactMeanBtn',{attrs:{"disabled-add-contact":invalid},on:{"addContact":function($event){return _vm.addContact()}}})],1)],2)]}}])}),_c('validation-provider',{ref:"phone_value_contact",attrs:{"rules":"required","name":"teléfono"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
return [_c('v-text-field',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSetValueContact.tlf),expression:"showSetValueContact.tlf"}],attrs:{"prepend-inner-icon":"mdi-phone","error-messages":errors[0]},model:{value:(_vm.form.value_contact),callback:function ($$v) {_vm.$set(_vm.form, "value_contact", $$v)},expression:"form.value_contact"}},[_c('v-tooltip',{attrs:{"slot":"prepend","bottom":""},slot:"prepend",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"30"},on:{"click":function($event){return _vm.viewSelectTypeContact()}}},on),[_vm._v(" mdi-chevron-left ")])]}}],null,true)},[_c('span',[_vm._v("Volver a seleccionar tipo de contacto.")])]),_c('template',{slot:"append-outer"},[_c('AddContactMeanBtn',{attrs:{"disabled-add-contact":invalid},on:{"addContact":function($event){return _vm.addContact()}}})],1)],2)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }