/*
|-------------------------------------------------------------------------------
| modules/languges/mutations.js
|-------------------------------------------------------------------------------
| The Vuex data store for languges that may belong to the user
*/

export default {

  /**
     * Indica el estado en el que se encuentra la obtención de lenguajes disponibles en el servidor
     *
     * @param {object} state
     * @param {numeric} status
     */
  setLanguagesStatusLoad(state, status) {
    state.dataLanguagesStatus = status;
  },

  /**
     * Establece los idiomas
     *
     * @param {object} state
     * @param {array} languages
     */
  setLanguages(state, languages) {
    state.languages = languages;
  },

};
