/*
|-------------------------------------------------------------------------------
| modules/contact_us/mutations.js
|-------------------------------------------------------------------------------
| The mutations Vuex for contact us module
*/

export default {

  /**
   * Indica el estado en el que se encuentra el envío de mensaje,
   * tener en cuenta de que en el backend se queda en un proceso de colas.
   *
   * @param {object} state
   * @param {numeric} status
   */
  setSendMessageStatusLoad(state, status) {
    state.sendMessageStatusLoad = status;
  },

  setErrorsSendMessage(state, errors) {
    state.errorsSendMessage = errors;
  },

};
