import moment from 'moment';
import WorkAreaSelectComponent from '@/components/WorkAreaSelectComponent/WorkAreaSelectComponent';
import * as consts from '@/constants.js';
import {
  mdiTranslate,
  mdiBriefcaseOutline,
  mdiClose,
  mdiCar,
} from '@mdi/js';
import { mapGetters } from 'vuex';

export default {

  name: 'page-admin-request-collaborations',

  data: () => ({
    icons: {
      mdiTranslate,
      mdiBriefcaseOutline,
      mdiClose,
      mdiCar,
    },

    isEdit: false,
    requestCollaborationId: null,
    full_name_location_default: null,
    form: {
      subject: '',
      location: null,
      start_date: null,
      start_time: null,
      end_date: null,
      vehicle: '',
      description: '',
      gender: null,
      service_id: null,
      idioms: [],
      age_range_lower: null,
      age_range_upper: null,
    },

    minStartDate: moment().toISOString(),

    menuStartDate: false,
    menuEndDate: false,
    menuStartTime: false,

    searchIdiom: null,
    filtersLanguages: [],

  }),

  created() {
    moment.locale('es');
    this.$store.dispatch('services/getServices');
    this.$store.dispatch('languages/getLanguages');
    if (this.$route.params.id) {
      this.isEdit = true;
      this.$store.dispatch('collaboration/get', this.$route.params.id);
    }
  },

  computed: {
    computedStartDateFormattedMomentjs() {
      return this.dateFormattedMomentjs(this.form.start_date);
    },

    computedEndDateFormattedMomentjs() {
      return this.dateFormattedMomentjs(this.form.end_date);
    },
    requestCollaboration() {
      return this.$store.getters['requestCollaboration/getRequestCollaboration'];
    },

    collaborationStatusLoad() {
      return this.$store.getters['requestCollaboration/getRequestCollaborationStatusLoad'];
    },

    minEndDate() {
      // Añadiendo un 1 deja seleccionar el mismo día
      return this.form.start_date ? moment(this.form.start_date).add(1, 'day').toISOString() : '';
    },

    makingRequest() {
      return this.$store.getters['requestCollaboration/getRequestCollaborationStatusLoad'] === consts.REQUEST_HAS_STARTED;
    },

    currentCollaborationRequest() {
      return this.$store.getters['collaboration/get'];
    },

    loadingCurrentRequest() {
      return this.$store.getters['collaboration/getStatusLoad'] === consts.REQUEST_HAS_STARTED;
    },

    ...mapGetters({
      services: 'services/getServices',
      languages: 'languages/getLanguages',
      errorsForm: 'requestCollaboration/getErrorsFormrequestCollaboration',
    }),

    linkCancelAdminCollaborationRequest() {
      return this.isEdit
        ? {
          name: 'Collaboration',
          params: {
            id: this.$route.params.id,
          },
        }
        : { name: 'Collaborations' };
    },

  },

  watch: {
    requestCollaboration(requestCollaboration) {
      if (requestCollaboration !== null) {
        this.$router.push({
          name: 'Collaboration',
          params: { id: requestCollaboration.id },
        });
      }
    },

    currentCollaborationRequest(currentCollaboration) {
      const profile = this.$store.getters['auth/getProfile'];
      if (!currentCollaboration || currentCollaboration.elapsed || currentCollaboration.user.id !== profile.id) {
        this.$router.push({ name: 'Collaborations' });
      }
      this.setCurrentRequestCollaboration(currentCollaboration);
    },

    searchIdiom(val) {
      val && val.length > 2 && this.querySelectionsLanguges(val);
    },

    errorsForm(currentErrorsForm) {
      this.$refs['observer-form-request-collaboration'].reset();

      const errors = {};

      for (const field in currentErrorsForm) {
        errors[field] = [];
        for (const i_error in currentErrorsForm[field]) {
          errors[field].push(currentErrorsForm[field][i_error]);
        }
      }

      this.$refs['observer-form-request-collaboration'].setErrors(errors);
    },

  },

  methods: {
    selectedLocation(selectedLocation) {
      console.log(selectedLocation);
      if (selectedLocation.currentAdminAreaLevel == null) {
        this.form.location = null;
        return;
      }

      this.form.location = {
        country: selectedLocation.newLocation.country,
        admin_area: {
          id: selectedLocation.newLocation.adminAreaId,
          level: selectedLocation.newLocation.adminAreaLevel,
        }
      };
    },

    dateFormattedMomentjs(date) {
      return date ? moment(date).format('LL') : '';
    },

    async adminRequest() {
      this.$refs['observer-form-request-collaboration'].reset();

      const isValid = await this.$refs['observer-form-request-collaboration'].validate();

      if (!isValid) {
        this.$store.dispatch('requestCollaboration/stop');
        return;
      }

      this.$store.dispatch('requestCollaboration/admin', this.form);
    },

    setCurrentRequestCollaboration(currentCollaboration) {
      const {
        id,
        subject,
        start_date,
        start_time,
        end_date,
        description,
        vehicle,
        location,
        gender,
        age_range_lower,
        age_range_upper,
        service,
        idioms,
      } = currentCollaboration;
      this.form.id = id;
      this.form.subject = subject;
      this.form.start_date = start_date;
      this.form.start_time = start_time;
      this.form.end_date = end_date;
      if (location) {
        this.form.location = {
          country: location.data.country,
          admin_area: {
            level: location.data.level,
            id: location.data.id,
          },
        };
      } else {
        this.form.location = null;
      }
      this.full_name_location_default = location ? location.name : null;
      this.form.description = description;
      this.form.vehicle = vehicle;
      this.form.gender = gender ? gender.value : null;
      this.form.age_range_lower = age_range_lower;
      this.form.age_range_upper = age_range_upper;
      this.filtersLanguages = idioms;
      this.form.idioms = this.filtersLanguages.map(idiom => idiom.country_code);
      this.form.service_id = service ? service.id : null;
    },

    querySelectionsLanguges(v) {
      this.filtersLanguages = this.languages.filter(l => (l.idiom || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1);
    },

  },

  components: {
    WorkAreaSelectComponent,
  },

};
