/*
|-------------------------------------------------------------------------------
| modules/collaborations/request/mutations.js
|-------------------------------------------------------------------------------
| The mutations Vuex for request collaborations module
*/

export default {
  setRequestCollaborationStatusLoad(state, status) {
    state.requestCollaborationStatusLoad = status;
  },

  setRequestCollaboration(state, requestCollaboration) {
    state.requestCollaboration = requestCollaboration;
  },

  setErrorsFormrequestCollaboration(state, errors) {
    state.errorsFormrequestCollaboration = errors;
  },

  setCollaborationRequests(state, collaborationRequests) {
    state.collaborationRequests = collaborationRequests;
  },

  setCollaborationRequestsStatusLoad(state, status) {
    state.collaborationRequestsStatusLoad = status;
  },
};
