/*
|-------------------------------------------------------------------------------
| modules/services/getters.js
|-------------------------------------------------------------------------------
| The getters Vuex for services module
*/

export default {

  /**
	 * Obtiene el estado en el que se encuentra el proceso de obtención de los
	 * servicios que puede prestar un usuario.
	 */
  getDataServicesStatus(state) {
    return state.dataServicesStatus;
  },

  /**
	 * Obtiene los servicios que puede prestar un usuario.
	 *
	 * @param {object} state
	 */
  getServices(state) {
    return state.services;
  },

};
