/*
|-------------------------------------------------------------------------------
| modules/races/mutations.js
|-------------------------------------------------------------------------------
| The Vuex data store for races that may belong to the user
*/

export default {

  /**
     * Indica el estado en el que se encuentra la obtención de razas disponibles en el servidor
     *
     * @param {object} state
     * @param {numeric} status
     */
  setRacesStatusLoad(state, status) {
    state.dataRacesStatus = status;
  },

  /**
     * Establece las razas
     *
     * @param {object} state
     * @param {array} races
     */
  setRaces(state, races) {
    state.races = races;
  },

};
