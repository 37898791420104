import Vue from 'vue';
import constants from '../config/constants.env.js';

const Constants = {};

Constants.install = function (Vue, options) {
  Vue.prototype.$getConst = key => constants[key];
};

Vue.use(Constants);
