/*
|-------------------------------------------------------------------------------
| API api/user.js
|-------------------------------------------------------------------------------
| The api for api user
*/

export default {

  /**
	 * Obtiene los datos de un usuario.
	 *
	 * @param      {string}  idUser  The identifier user
	 * @return     {<type>}  The user.
	 */
  getUser(idUser) {
    return axios.get(`/api/user/${idUser}`);
  },

  /**
	 * Guarda la áreas de trabajo
	 *
	 * @param      {Object}  workAreas  The work areas
	 * @return     {Promise}
	 */
  saveWorkAreas(workAreas) {
    return axios.post('/api/user/managment/work-areas', {
      work_areas: workAreas,
    });
  },

  /**
   * Cambia la contraseña
   * @param {object} dataChangePassword 
   */
  changePassword(dataChangePassword) {
    return axios.post('/api/user/managment/password', dataChangePassword);
  },

  /**
   * Oculta el perfil
   * @param {int} dataChangePassword el tiempo en minutos a ocultar
   * @return     {Promise} 
   */
  hideProfile(hiddenProfileTime) {
    return axios.post('/api/user/managment/hide-profile', {
      hidden_profile_time: hiddenProfileTime,
    });
  },

  /**
   * Muestra el perfil
   * @return     {Promise} 
   */
  showProfile() {
    return axios.post('/api/user/managment/show-profile');
  },

  /**
	 * Guarda los medios de contacto
	 *
	 * @param      {Object}  contactMeans  The work areas
	 * @return     {Promise}
	 */
  saveContactMeans(contactMeans) {
    return axios.post('/api/user/managment/contact-means', {
      contact_means: contactMeans,
    });
  },

  /**
	 * Update services that a user play.
	 *
	 * @param      {Object}  services
	 * @return     {Promise}
	 */
  saveServices(services) {
    const serviceIds = [];
    services.forEach((i) => {
      serviceIds.push(i.id);
    });

    return axios.post('/api/user/managment/services', {
      services: serviceIds,
    });
  },

  /**
	 * Update work equipment that a user play.
	 *
	 * @param      {Object}  workEquipment
	 * @return     {Promise}
	 */
  saveWorkEquipment(workEquipment) {
    const workEquipmentIds = [];
    workEquipment.forEach((i) => {
      workEquipmentIds.push(i.id);
    });

    return axios.post('/api/user/managment/work-equipment', {
      work_equipment: workEquipmentIds,
    });
  },

  /**
	 * Update associations user's.
	 *
	 * @param      {Object}  associations
	 * @return     {Promise}
	 */
  saveAssociations(associations) {
    const associationsIds = [];
    associations.forEach((i) => {
      associationsIds.push(i.id);
    });

    return axios.post('/api/user/managment/associations', {
      associations: associationsIds,
    });
  },

  /**
   *
   * @param      {Object}  languages
	 * @return     {Promise}
   */
  saveLanguages(languages) {
    const countryCodes = [];
    languages.forEach((language) => {
      countryCodes.push(language.country_code);
    });

    return axios.post('/api/user/managment/languages', {
      languages: countryCodes,
    });
  },

  /**
   *
   * @param      {Object}  race
	 * @return     {Promise}
   */
  saveRace(race) {
    return axios.post('/api/user/managment/race', {
      race: race.id,
    });
  },

  /**
   *
   * @param      {String}  saveMoreInformation
   * @return     {Promise}
   */
  saveOfficeName(officeName) {
    return axios.post('/api/user/managment/office-name', {
      office_name: officeName,
    });
  },

  /**
   *
   * @param      {String}  moreInformation
   * @return     {Promise}
   * */
  saveMoreInformation(moreInformation) {
    return axios.post('/api/user/managment/more-information', {
      more_information: moreInformation,
    });
  },

  /**
   * @param {String} ageRange
   * @return     {Promise}
   */
  saveAgeRange(ageRange) {
    return axios.post('/api/user/managment/age-range', {
      age_range: ageRange,
    });
  },

};
