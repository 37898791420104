/*
|-------------------------------------------------------------------------------
| modules/collaborations/request/state.js
|-------------------------------------------------------------------------------
| The state Vuex for request collaborations module
*/

import * as consts from '@/constants.js';

export default {
  requestCollaborationStatusLoad: consts.REQUEST_WITHOUT_STARTING,
  requestCollaboration: null,
  errorsFormrequestCollaboration: null,
  collaborationRequestsStatusLoad: consts.REQUEST_WITHOUT_STARTING,
  collaborationRequests: [],
};
